<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in</small>
          </div>
        </div>
        <div
          class="card-body px-lg-5 py-lg-5"
          style="margin-top: -50px !important"
        >
          <form role="form" @submit.prevent="onSignin">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Username"
              addon-left-icon="ni ni-single-02"
              v-model="model.username"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <!-- <base-button type="primary" class="my-4"> -->
              <button type="submit" class="btn btn-primary">Sign in</button>
              <!-- </base-button> -->
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <small
            ><a :href="forget_pass" target="blank" class="text-light"
              >Forgot password?</a
            ></small
          >
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"
            ><small>Create new account</small></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FORGET_PASSWORD from "./../common/forget-password-url";
export default {
  name: "login",
  data() {
    return {
      model: {
        username: "",
        password: "",
        forget_pass: "",
      },
    };
  },
  created() {
    this.forget_pass = FORGET_PASSWORD + "/password-reset/";
  },
  computed: {
    comparePasswords() {
      return this.newPassword !== this.repeatRassword
        ? "Passwords do not match"
        : "";
    },
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        //console.log('token 1=')
        this.$router.push("/dashboard");
      }
    },
    token(value) {
      //console.log('token 2=', value)
    },
  },
  methods: {
    onSignin() {
      //console.log('username', this.model.username, 'password', this.model.password)
      this.$store.dispatch("signUserIn", {
        username: this.model.username,
        password: this.model.password,
      });
    },
  },
};
</script>
<style></style>
