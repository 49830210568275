<template>
  <div>
    <base-header
      class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
      :style="{ minHeight: '400px', backgroundSize: 'cover', backgroundPosition: 'center top', 
      backgroundImage: `url(${cover_photo})`  }"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-primary opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-7 col-md-10">
            <div style="width: 100% !important">
              <h3 class="display-4 text-white">Hello {{ first_name }}</h3>
              <p class="text-white mt-0 mb-5">{{ about_me }}</p>
            </div>

            <!-- <a href="#!" class="btn btn-info">Edit profile</a> -->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-4 order-xl-2 mb-5 mb-xl-0">
          <div class="card card-profile shadow">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      v-if="profile_photo"
                      :src="profile_photo"
                      class="rounded-circle"
                      style="border: 1px solid blue;"
                    />
                    <img
                      v-else
                      src="img/theme/team-4-800x800.jpg"
                      class="rounded-circle"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div
              class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"
            >
              <div class="d-flex justify-content-between"></div>
            </div>
            <div class="card-body pt-0 pt-md-4">
              <div class="row">
                <div class="col">
                  <div
                    class="
                      card-profile-stats
                      d-flex
                      justify-content-center
                      mt-md-5
                    "
                  >
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Votes</span>
                    </div>
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Coins</span>
                    </div>
                    <div>
                      <span class="heading">0</span>
                      <span class="description">Referral Bonus</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <h4>{{ first_name }} {{ first_name }}</h4>
                <div class="h5 font-weight-300" v-if="city && country">
                  <i class="ni location_pin mr-2"></i>{{ city }}, {{ country }}
                </div>
                <div class="h5" v-if="phone_number">
                  <i class="ni education_hat mr-2"></i>{{ phone_number }}
                </div>
                <div class="h5" v-if="whatsapp">
                  <i class="ni business_briefcase-24 mr-2"></i> {{ whatsapp }}
                </div>
                <hr class="my-4" />

                <!-- <a class="btn btn-primary" href="#">Upload Profile Photo</a> -->

                <form role="form" @submit.prevent="submitProfilePic" id="form2">
                      <div class="form-group">
                        <p align="center">
                          <label class="btn btn-outline-primary" style="padding:7px">
                            Select Profile Photo
                          <input
                              hidden="hidden"
                              type="file"
                              accept="image/*"
                              ref="file"
                              @change="selectImage"
                            />
                            </label>
                            <br>
                          <button class="btn btn-primary" style="padding:7px">
                            Submit
                          </button>
                        </p>
                  </div>
                </form>

                <p align="center" v-if="previewImage">
                  <span >
                    <img class="preview my-3" :src="previewImage" alt="" width="10%" />
                  </span>
                </p>
                <div  v-if="currentImage">
                  <div class="progress" style="height: 20px">
                    <div
                      class="progress-bar progress-bar-success progress-bar-striped progress-bar-animated"
                      role="progressbar"
                      :aria-valuenow="progress"
                      aria-valuemin="0"
                      aria-valuemax="100"
                      :style="{ width: progress + '%' }"
                      >
                        {{ progress }}%
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-8 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">My account</h3>
                </div>
                <div class="col-4 text-right">
                  <a href="#!" class="btn btn-sm btn-primary">Settings</a>
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent="submitProfile" id="form1">
                <div class="row"></div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Fist Name</label>
                      <input
                        type="text"
                        name="first_name"
                        v-model="first_name"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Last Name</label>
                      <input
                        type="text"
                        name="last_name"
                        v-model="last_name"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="">Adress</label>
                      <input
                        type="text"
                        name="address"
                        v-model="address"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="">State / City</label>
                      <input
                        type="text"
                        name="city"
                        v-model="city"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="">Country</label>
                      <input
                        type="text"
                        name="country"
                        v-model="country"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label class="">Postal Code</label>
                      <input
                        type="text"
                        name="postal_code"
                        v-model="postal_code"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Phone number / GSM</label>
                      <input
                        type="text"
                        name="phone_number"
                        v-model="phone_number"
                        required
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="">Whatsapp</label>
                      <input
                        type="text"
                        name="whatsapp"
                        v-model="whatsapp"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-group">
                        <label class=""> About Me </label>
                        <textarea
                          class="form-control"
                          name="about_me"
                          v-model="about_me"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn btn-success">Submit</button>
                <div class="clearfix"></div>
              </form>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import API_URL from "./../common/config";
import UploadService from "../services/UploadFilesService";

export default {
  name: "user-profile",
  data() {
    return {
      first_name: "",
      last_name: "",
      address: "",
      city: "",
      country: "",
      postal_code: "",
      about_me: "",
      phone_number: "",
      whatsapp: "",
      username: "",
      profile_photo: '',
      cover_photo: '',
      currentImage: undefined,
      previewImage: undefined,
      progress: 0,
    };
  },
  created() {
    this.profileDetails();
    this.username = this.$store.getters.user.username;
    this.getProfilePhoto()
  },
  methods: {
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
    },
    getProfilePhoto(){
      UploadService.getProfilePhoto().then((res)=>{
        this.cover_photo = "img/theme/profile-cover.jpg"
        if(res.data.length > 0){
          this.profile_photo = res.data[0].profile_photo
          this.cover_photo = res.data[0].profile_photo
        }
      })
    },
    submitProfilePic() {
      if(this.currentImage){
        this.progress = 0;
        // Initialize the form data           
        let formData = new FormData();

      //  Add the form data we need to submit 
        formData.append('profile_photo', this.currentImage);

        UploadService.uploadUserProfile(formData, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then((res)=>{

          if(res.data.data == true){

            this.progress = 0;
            this.currentImage = undefined;
            this.previewImage = undefined;

            this.getProfilePhoto()
          }
        }).catch((error) => {
          alert(`Error Uploading Profile Photo! " + ${error}`);
          this.progress = 0;
          this.currentImage = undefined;
        });
      } else {
        alert("Please Select the right Photo!");
      }
    },
    submitProfile() {
      const formData = {
        first_name: this.first_name,
        last_name: this.last_name,
        address: this.address,
        city: this.city,
        country: this.country,
        postal_code: this.postal_code,
        about_me: this.about_me,
        whatsapp: this.whatsapp,
        phone_number: this.phone_number,
      };
      //console.log('formData', formData)
      this.$store.dispatch("loadPersonalProfile", formData);
    },
    profileDetails() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "profile/user-details/", { headers: headers })
        .then((res) => {
          //console.log('profileDetails data 1', res.data)
          this.first_name = res.data[0].first_name;
          this.last_name = res.data[0].last_name;
          this.address = res.data[0].address;
          this.city = res.data[0].city;
          this.country = res.data[0].country;
          this.postal_code = res.data[0].postal_code;
          this.about_me = res.data[0].about_me;
          this.whatsapp = res.data[0].whatsapp;
          this.phone_number = res.data[0].phone_number;
        });
    },
  },
};
</script>
<style></style>
