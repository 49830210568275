<template>
  <div v-if="noLiveVideo">
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div class="col-xl-12 col-lg-12">
          <h3 style="color: #fff">
            Watch Video Rewards:
            <span>
              <img src="img/dh-coin.gif" width="35px" style="padding: 3px" />
              <img src="img/dh-coin.png" width="35px" style="padding: 3px" />
              <img src="img/dh-coin.gif" width="35px" style="padding: 3px" />
            </span>
            {{ videoViewedInfos }}
          </h3>
        </div>
        <div
          class="col-xl-12 col-lg-12"
          v-for="item in liveShowVote"
          v-bind:key="item.id"
        >
          <stats-card :sub-title="item.title" class="mb-4 mb-xl-0">
            <div>
              <p>Watch Video till end to Win up to N5million</p> 
            </div>

            <template slot="footer">
              <div v-if="hideTemplateRow" align="center">
                <!-- <RippleLoader :color="'#54f1d2'" /> -->
                <p align="center">
                  <FacebookLoader :color="'#54f1d2'" /><FacebookLoader />
                </p>
              </div>
              <div v-if="!hideTemplateRow">
              <div class="row" >
                <div
                  class="col-md-7"
                  v-for="vid in liveVideoData"
                  v-bind:key="vid.id"
                >
                  <div
                    class="card card-profile"
                    v-if="vid.video"
                    id="replay_button"
                  >
                    <Media
                      :kind="'video'"
                      :src="[vid.video]"
                      :poster="vid.video_thumb"
                      :controls="true"
                      :preload="false"
                      @play="havePlayed"
                      @ended="haveEnded"
                      @pause="havePaused"
                      ref="fish"
                      style="width: 100%"
                    >
                    </Media>
                    <div
                      id="replay_button_ontop"
                      v-if="is_replay"
                      align="center"
                      style="color: red !important;"
                    >
                      <h3>Thanks for Watching!</h3>
                      <p>You can Click the Next Video to Watch another Videos <br> Or you can Go to Replay Lists to watch more...<br>
                      <a :href="'/#/replay-video'">
                        <img
                          alt="Image placeholder"
                          src="img/replay-button.png"
                          width="75px"
                          align="center"
                      /></a>
                      <br> Answer below Questions and stand the <br> 
                      Change to Win up to N5million!</p>
                    </div>
                  </div>
                  <div
                    class="card card-profile"
                    v-else-if="item.video"
                    id="disable-mouse-video"
                  >
                    <video
                      id="video_ids"
                      ref="video_ids"
                      alt="Image placeholder"
                      controls
                      preload="none"
                      controlsList="nodownload nofullscreen noremoteplayback"
                      :src="item.video"
                      :poster="item.video_thumb"
                      class="card-img-top"
                      type="video/mp4"
                    ></video>
                  </div>
                </div>

                <div class="col-md-5">
                  <h2>Home Win Giveaway</h2>
                  <div align="left" v-if="isQuestionAnswered">
                    <span v-for="imgas in img_answer_countet" :key="imgas">
                      <img
                        src="img/rotating-gold-icon.gif"
                        :id="imgas"
                        width="50px"
                        align="center"
                      />
                    </span>
                    <span v-if="answeredScoresImg[0] != 'Null'">
                      <img
                        src="img/correct-marks.png"
                        v-if="answeredScoresImg[0] == 'Yes'"
                        width="50px"
                        align="center"
                      />
                      <img
                        src="img/wrong-marks.png"
                        v-if="answeredScoresImg[0] == 'No'"
                        width="50px"
                        align="center"
                      />
                    </span>
                    <span v-if="answeredScoresImg[1] != 'Null'">
                      <img
                        src="img/correct-marks.png"
                        v-if="answeredScoresImg[1] == 'Yes'"
                        width="50px"
                        align="center"
                      />
                      <img
                        src="img/wrong-marks.png"
                        v-if="answeredScoresImg[1] == 'No'"
                        width="50px"
                        align="center"
                      />
                    </span>
                    <span v-if="answeredScoresImg[2] != 'Null'">
                      <img
                        src="img/correct-marks.png"
                        v-if="answeredScoresImg[2] == 'Yes'"
                        width="50px"
                        align="center"
                      />
                      <img
                        src="img/wrong-marks.png"
                        v-if="answeredScoresImg[2] == 'No'"
                        width="50px"
                        align="center"
                      />
                    </span>
                    <span v-if="answeredScoresImg[3] != 'Null'">
                      <img
                        src="img/correct-marks.png"
                        v-if="answeredScoresImg[3] == 'Yes'"
                        width="50px"
                        align="center"
                      />
                      <img
                        src="img/wrong-marks.png"
                        v-if="answeredScoresImg[3] == 'No'"
                        width="50px"
                        align="center"
                      />
                    </span>
                    <span v-if="answeredScoresImg[4] != 'Null'">
                      <img
                        src="img/correct-marks.png"
                        v-if="answeredScoresImg[4] == 'Yes'"
                        width="50px"
                        align="center"
                      />
                      <img
                        src="img/wrong-marks.png"
                        v-if="answeredScoresImg[4] == 'No'"
                        width="50px"
                        align="center"
                      />
                    </span>
                  </div>
                  <p v-if="disableNoAnswerButton" align="center">
                    <b>Answer 5 Questions correctly and Win up to N5million</b>
                    <br /><span style="font-size: 13px; color: red"
                      >5 Questions will cost you N200.</span
                    >
                  </p>
                  <!-- if Answered 5 Questions -->
                  <div v-if="isAnswersCompleted" style="margin-top: 20px">
                    <!-- <h2>Congratulation!</h2> -->
                    <p>You have completed this Question</p>
                    <h1>You Scored {{ correctAnswersCount }}/5</h1>
                    <div v-if="correctAnswersCount == 5">
                      <h2>Congratulation!</h2>
                      <p>
                        <b>
                          You have Successful Enter the HomeWin Draw!<br />
                          The more Play Videos and Answer Questions; the more your  Better Chances to Win!
                        </b>
                      </p>
                    </div>
                    <div v-if="correctAnswersCount != 5">
                      <h2 style="color: red">Try Again Later!</h2>
                      <p>
                        <b>
                          The more Play Videos and Answer Questions; the more your  Better Chances to Win!
                        </b>
                      </p>
                    </div>
                  </div>
                </div> <!-- end Col-md-5 -->
              </div> <!-- end row -->
                  <!-- start Table -->
                  <div class="row" v-if="!isAnswerLoading">
                    <div class="col-md-12">
                      <b>Select Videos</b>
                      <div
                        class="flex-container"
                        v-for="i in range(1, liveVideoIndex)"
                        :key="i"
                      >
                        <div>
                          <a style="cursor: pointer" @click="selectVideos(i)"
                            ><b>{{ i }}</b> ,
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div
                        v-for="quest in getQuestionsData"
                        v-bind:key="quest.id"
                      >
                        <div
                          class="card bg-default shadow"
                          v-if="quest.sort_order == answerCounter"
                        >
                          <div
                            class="card-header bg-transparent border-0"
                            v-if="quest.sort_order == answerCounter"
                          >
                            <h3
                              class="text-white mb-0"
                              v-if="quest.sort_order == answerCounter"
                            >
                              {{ quest.question_title }}
                            </h3>
                          </div>
                          <div class="table-responsive">
                            <table
                              class="
                                table
                                align-items-center
                                table-dark table-flush
                              "
                              v-if="quest.sort_order == answerCounter"
                            >
                              <!-- Dark table -->
                              <thead
                                class="thead-dark"
                                v-if="quest.sort_order == answerCounter"
                              >
                                <th width="25%">
                                  <span
                                    style="
                                      color: #fff !important;
                                      font-size: 16px !important;
                                    "
                                    ><b
                                      >Question: {{ quest.sort_order }}</b
                                    ></span
                                  >
                                </th>
                                <th
                                  width="70%"
                                  style="
                                    margin-left: -100px !important;
                                    color: #fff !important;
                                    font-size: 16px !important;
                                  "
                                >
                                  <b>Answers Options</b>
                                </th>
                                <th
                                   width="5%"
                                  style="
                                    color: #fff !important;
                                    font-size: 16px !important;
                                  "
                                >
                                  *
                                </th>
                              </thead>
                              <!-- v-if="item.questions_id == quest.questions_id && quest.sort_order == answerCounter" -->
                              <div
                                v-for="item in getAnswersData"
                                v-bind:key="item.id"
                              >
                                <tbody
                                  v-if="
                                    item.questions_id == quest.questions_id &&
                                    quest.sort_order == answerCounter
                                  "
                                >
                                  <input
                                    type="hidden"
                                    ref="questions_id"
                                    :value="item.questions_id"
                                  />
                                  <input
                                    type="hidden"
                                    ref="correct_ans"
                                    :value="item.correct"
                                  />
                                  <tr>
                                    <td width="25%">A.</td>
                                    <td width="70%">{{ item.answer_a }}</td>
                                    <td  width="5%">
                                      <input
                                        type="radio"
                                        ref="answer"
                                        name="groupOfDefaultRadios"
                                        value="A"
                                        v-model="picked"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>B.</td>
                                    <td width="100px">{{ item.answer_b }}</td>
                                    <td>
                                      <input
                                        type="radio"
                                        ref="answer"
                                        name="groupOfDefaultRadios"
                                        value="B"
                                        v-model="picked"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>C.</td>
                                    <td width="100px">{{ item.answer_c }}</td>
                                    <td>
                                      <input
                                        type="radio"
                                        ref="answer"
                                        name="groupOfDefaultRadios"
                                        value="C"
                                        v-model="picked"
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>D.</td>
                                    <td width="100px">{{ item.answer_d }}</td>
                                    <td>
                                      <input
                                        type="radio"
                                        ref="answer"
                                        name="groupOfDefaultRadios"
                                        value="D"
                                        v-model="picked"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </div>
                              <!-- Dark table -->
                            </table>
                          </div>
                        </div>
                      </div>
                      <div align="center" v-if="disableNoAnswerButton">
                        <!-- <a class="btn btn-disabled" v-if="isAnswerLoading" style="margin-top:15px; border-color:#6E6E6E !important;" align="center">Loading...</a> -->

                        <div v-if="!disableAnswerButton">
                          <a
                            @click="AnswerQuestuonFunc()"
                            class="btn btn-primary"
                            style="color: #fff !important; margin-top: 15px"
                            v-if="haveFundForQuestions"
                            align="center"
                            >Answer Question!</a
                          >
                          <a
                            class="btn btn-warning"
                            :href="'/#/wallet'"
                            style="color: #fff !important; margin-top: 15px"
                            v-if="!haveFundForQuestions"
                            align="center"
                            >No Fund, Topup to Play</a
                          >
                        </div>

                        <a
                          class="btn btn-disabled"
                          v-if="disableAnswerButton"
                          style="
                            margin-top: 15px;
                            border-color: #6e6e6e !important;
                          "
                          align="center"
                          >Answering...</a
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end Table -->
              </div>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- Dark table -->
      <div class="row">
        <div class="col">
          <div class="card bg-default shadow" style="padding: 20px !important">
            <div class="card-header bg-transparent border-0">
              <h3 class="text-white mb-0">Vote for your favorite Contestant</h3>
            </div>
            <div class="row">
              <div
                class="col-xl-6 col-lg-6"
                v-for="item in contestantToVote"
                v-bind:key="item.id"
              >
                <div class="card" v-if="is_merged == 'No'">
                  <!-- <div class="card-header" >
                            <h5 class="h3 mb-0">Search Result (s)</h5>
                          </div> -->
                  <div class="card-header d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <a>
                        <img
                          v-if="item.photo"
                          :src="item.photo"
                          class="avatar"
                        />
                      </a>
                      <div class="mx-3">
                        <a class="text-dark font-weight-700"
                          >{{ item.first_name }} {{ item.last_name }}</a
                        >
                        <small class="d-block text-sm"
                          >Contestant ID: {{ item.contestant_id }}</small
                        >
                      </div>
                    </div>

                    <div
                      class="text-right ml-auto"
                      v-if="item.status == 'Evicted'"
                    >
                      <b-button class="btn btn-sm btn-danger btn-icon">
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Evicted</span>
                      </b-button>
                    </div>
                    <div class="text-right ml-auto" v-else-if="isEnableVote">
                      <b-button
                        v-b-modal.modal-prevent-closing
                        class="btn btn-sm btn-success btn-icon"
                        v-on:click="startContestantVote(item.contestant_id)"
                      >
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Vote</span>
                      </b-button>
                    </div>
                    <div class="text-right ml-auto" v-else-if="!isEnableVote">
                      <b-button class="btn btn-sm btn-danger btn-icon">
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Add Fund to Vote</span>
                      </b-button>
                    </div>
                  </div>
                  <div class="card-body">
                    <div id="show_img">
                      <img
                        v-if="item.photo"
                        alt="Image placeholder"
                        :src="item.photo"
                        class="img-fluid rounded"
                        width="100%"
                      />
                      <div
                        id="show_img_ontop"
                        v-if="item.status == 'Evicted'"
                        align="center"
                      >
                        <img
                          alt="Image placeholder"
                          src="img/evicted.png"
                          width="80%"
                          align="center"
                        />
                      </div>
                    </div>
                    <br />
                    <div class="row align-items-center my-3 pb-3 border-bottom">
                      <div class="col-sm-6">
                        <div class="icon-actions">
                          <a href="#" class="like active">
                            <i class="ni ni-like-2"></i>
                            <span class="text-muted">
                              {{ item.vote_count }}</span
                            >
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- v-if == is_merged == Yes -->
                <div class="card" v-if="is_merged == 'Yes'">
                  <div class="card-header d-flex align-items-center">
                    <div class="d-flex align-items-center">
                      <a>
                        <img
                          v-if="item.photo"
                          :src="item.photo"
                          class="avatar"
                        />
                      </a>
                      <div class="mx-3">
                        <a class="text-dark font-weight-700"
                          ><h3>{{ item.title }}</h3></a
                        >
                        <small class="d-block text-sm"
                          >Contestant ID: {{ item.contestant_id }}</small
                        >
                      </div>
                    </div>

                    <div
                      class="text-right ml-auto"
                      v-if="item.status == 'Evicted'"
                    >
                      <b-button class="btn btn-sm btn-danger btn-icon">
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Evicted</span>
                      </b-button>
                    </div>
                    <div class="text-right ml-auto" v-else-if="isEnableVote">
                      <b-button
                        v-b-modal.modal-prevent-closing
                        class="btn btn-sm btn-success btn-icon"
                        v-on:click="startContestantVote(item.contestant_id)"
                      >
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Vote</span>
                      </b-button>
                    </div>
                    <div class="text-right ml-auto" v-else-if="!isEnableVote">
                      <b-button class="btn btn-sm btn-danger btn-icon">
                        <span class="btn-inner--icon"
                          ><i class="ni ni-fat-add"></i
                        ></span>
                        <span class="btn-inner--text">Add Fund to Vote</span>
                      </b-button>
                    </div>
                  </div>
                  <div class="card-body">
                    <!-- img-fluid rounded -->
                    <div class="row">
                      <div id="show_img">
                        <div
                          v-for="imgs in contestantPhotos"
                          v-bind:key="imgs.id"
                          class=""
                        >
                          <div>
                            <div style="float: right; width: 50%">
                              <img
                                v-if="
                                  item.contestant_old_id1 ==
                                    imgs.contestant_id &&
                                  imgs.gender == 'Female'
                                "
                                alt="Image placeholder"
                                :src="imgs.photo"
                                width="100%"
                              />
                            </div>
                            <div style="float: right; width: 50%">
                              <img
                                v-if="
                                  item.contestant_old_id2 ==
                                    imgs.contestant_id &&
                                  imgs.gender == 'Female'
                                "
                                alt="Image placeholder"
                                :src="imgs.photo"
                                width="100%"
                              />
                            </div>
                            <div
                              style="
                                float: left;
                                width: 50%;
                                overflow: hiiden;
                                white-space: nowrap;
                              "
                            >
                              <img
                                v-if="
                                  item.contestant_old_id1 ==
                                    imgs.contestant_id && imgs.gender == 'Male'
                                "
                                alt="Image placeholder"
                                :src="imgs.photo"
                                width="100%"
                              />
                            </div>
                            <div
                              style="
                                float: left;
                                width: 50%;
                                overflow: hiiden;
                                white-space: nowrap;
                              "
                            >
                              <img
                                v-if="
                                  item.contestant_old_id2 ==
                                    imgs.contestant_id && imgs.gender == 'Male'
                                "
                                alt="Image placeholder"
                                :src="imgs.photo"
                                width="100%"
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          id="show_img_ontop"
                          v-if="item.status == 'Evicted'"
                          align="center"
                        >
                          <img
                            alt="Image placeholder"
                            src="img/evicted.png"
                            width="80%"
                            align="center"
                          />
                        </div>
                      </div>
                    </div>
                    <hr style="margin-bottom: 0px" />
                    <div class="row align-items-center my-3 pb-3 border-bottom">
                      <div class="col-sm-6">
                        <div class="icon-actions">
                          <a href="#" class="like active">
                            <i class="ni ni-like-2"></i>
                            <span class="text-muted">
                              {{ item.vote_count }}</span
                            >
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Dark table -->
    </div>
    <div>
      <div v-for="item in contestantToVote" v-bind:key="item.id">
        <!-- hide-backdrop -->
        <b-modal
          v-if="item.contestant_id == constestantId"
          :header-bg-variant="headerBgVariant"
          :header-text-variant="headerTextVariant"
          id="modal-prevent-closing"
          ref="modal"
          title="Vote Now!"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleOk"
        >
          <div class="d-block">
            <p>
              <b>Vote for Contestant:</b> {{ item.first_name }}
              {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id
              }}<br />Vote Cost: N{{ voting_cost }}/vote
            </p>
          </div>

          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              :state="nameState"
              :label="minimum_voting"
              label-for="name-input"
              invalid-feedback="Vote is required"
            >
              <b-form-select
                id="name-input"
                v-model="voteCount"
                :options="variants"
                :state="nameState"
                required
              ></b-form-select>
              <!-- <b-form-input
                  id="name-input"
                  v-model="name"
                  :state="nameState"
                  required
                  ></b-form-input> -->
            </b-form-group>
          </form>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import Media from "@dongido/vue-viaudio";
import { setTimeout } from "timers";
import { FacebookLoader, EllipsisLoader, RippleLoader } from "vue-spinners-css";

export default {
  name: "live-show-vote-for-dashbaord",
  props: ["contest_id"],
  components: {
    Media,
    EllipsisLoader,
    RippleLoader,
  },
  data() {
    return {
      liveShowVote: "",
      contestantToVote: "",
      isEnableVote: "",
      walletBalanceAmount: "",
      Contestant: "",
      search: "",
      constestantId: "",
      walletBalance: "",
      name: "",
      nameState: null,
      submittedNames: [],
      voteCount: "",
      variants: [1, 2, 4, 5, 10, 20, 30, 40, 50, 60, 80, 100],
      headerBgVariant: "primary",
      headerTextVariant: "dark",
      voting_cost: "",
      minimum_voting: "",
      minimum_vot_qty: "",
      token: JSON.parse(localStorage.getItem("userData")).token,
      isModalFormTrue: false,
      isVideoEnded: false,
      videoDuration: "",
      videoCurrentTine: 0.0,
      videoCurrentTimeGet: 0.0,
      liveVideoData: "",
      live_video_id: null,
      is_replay: false,
      getQuestionsData: "",
      getAnswersData: "",
      answerCounter: 1,
      answer_count: 0,
      picked: null,
      correct_ans: "",
      img_answer_countet: [1, 2, 3, 4, 5],
      questions_id: null,
      disableAnswerButton: false,
      disableNoAnswerButton: true,
      answeredScoresImg: "",
      isAnswerLoading: true,
      isAnswersCompleted: false,
      correctAnswersCount: "",
      hideTemplateRow: true,
      haveFundForQuestions: true,
      videoViewedInfos: "",
      is_merged: "No",
      contestantPhotos: "",
      Index: 0,
      liveVideoIndex: 0,
      isQuestionAnswered: false,
      noLiveVideo: true,
    };
  },
  created() {
    this.getLiveShowVoteDetails();
    this.getContestantToVote();
    this.getContestantPhotos();
    this.checkWalletBalance();
    this.getLiveVideo();
    this.updateVideoUserStatusGet();
    this.updateAnsweredQuestions();
    this.haveFunToAnswerQuestion();
    this.watchVideoReward();
  },
  mounted() {
    setTimeout(() => {
      this.updateVideoUserStatusGet();
      this.updateAnsweredQuestions();
      this.isAnswerLoading = false;
      this.hideTemplateRow = false;
      this.haveFunToAnswerQuestion();

      setTimeout(() => {
        this.updateVideoUserStatusGet();
      }, 2000);
    }, 3000);
  },
  updated() {},
  methods: {
    havePaused() {
      this.videoCurrentTine = this.$refs.fish[0].currentTime;
      this.updateVideoUserStatusPost();
    },
    haveEnded() {
      this.videoCurrentTine = this.$refs.fish[0].currentTime;
      //this.$refs.fish[0].currentTime = this.videoCurrentTine
      this.$refs.fish[0].controls = false;
      this.isVideoEnded = true;
      //this.is_replay = true;
      this.updateVideoUserStatusPost();
    },
    havePlayed() {
      this.videoDuration = this.$refs.fish[0].duration;
      var videoCurrentTine = this.$refs.fish[0].currentTime;
      if (videoCurrentTine < 0.05) {
        this.updateVideoUserStatusPost();
        if (this.videoCurrentTimeGet != null) {
          this.$refs.fish[0].currentTime = this.videoCurrentTimeGet;
        }
      } else {
        this.updateVideoUserStatusGet();
        this.$refs.fish[0].currentTime = this.videoCurrentTimeGet;
      }
    },
    getLiveVideo() {
      var headers = { Authorization: `Token ${this.token}` };

      axios
        .get(API_URL + "live-video/get-video/" + this.contest_id + "/", {
          headers: headers,
        })
        .then((res) => {
          this.liveVideoIndex = res.data.length;
          this.liveVideoData = res.data.filter(
            (data, index) => index == this.Index
          );
          this.live_video_id = this.liveVideoData[0].live_video_id;
          //console.log("live-video 1b ==", this.live_video_id);
          this.getQuestions(this.contest_id, this.live_video_id);
          //console.log("live-video 1c ==", this.live_video_id);
          if(!res.data){
            this.noLiveVideo = false
          }
          console.log('this.liveVideoIndex', res.data)
        })
        .catch((err) => {
          //console.log('live-video err ==', err)
        });
    },
    selectVideos(i) {
      this.Index = i - 1;
      this.getLiveVideo()
      //this.updateAnsweredQuestions()
      //this.updateVideoUserStatusGet()
    },
    range: function (start, end) {
      if(!end){
        end = 0
        this.noLiveVideo = false
      }
      return Array(end - start + 1)
        .fill()
        .map((_, idx) => start + idx);
    },
    getQuestions(contest_id, live_video_id) {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(
          API_URL +
            "home-win/get-questions/" +
            contest_id +
            "/" +
            live_video_id +
            "/",
          { headers: headers }
        )
        .then((res) => {
          this.getQuestionsData = res.data.data1;
          this.getAnswersData = res.data.data2;
          this.updateAnsweredQuestions()
          this.updateVideoUserStatusGet()
          
        });
    },
    AnswerQuestuonFunc() {
      if (this.picked != null) {
        this.disableAnswerButton = true;
        //this.answerCounter += 1
        //this.answer_count = this.answerCounter - 1
        var img_down = 6 - this.answer_count;
        var i = 0;
        this.img_answer_countet.length = 0;
        for (i = 1; i < img_down; i++) {
          this.img_answer_countet.push(i);
        }

        if (this.picked == this.$refs.correct_ans[0].value) {
          this.correct_ans = this.$refs.correct_ans[0].value;

          setTimeout(() => {
            this.AnsweredQuestionsPost(
              this.picked,
              this.correct_ans,
              this.answerCounter
            );
            alert("You Picked the Right Answer!");
            this.disableAnswerButton = false;
          }, 5000);
        } else {
          setTimeout(() => {
            this.AnsweredQuestionsPost(
              this.picked,
              this.correct_ans,
              this.answerCounter
            );
            alert("You Picked the Wrong Answer!");
            this.disableAnswerButton = false;
          }, 5000);
        }
      }
    },
    updateAnsweredQuestions() {
      
      if (this.live_video_id != null) {
        var headers = { Authorization: `Token ${this.token}` };
        axios
          .get(
            API_URL +
              "home-win/get-answered-questions/" +
              this.live_video_id +
              "/",
            { headers: headers }
          )
          .then((res) => {
            if (!res.data.data == false) {
              this.isQuestionAnswered = true
              this.answer_count = res.data.data[0].answer_count;
              this.answerCounter = this.answer_count + 1;
              this.img_answer_countet.length = 5 - this.answer_count;
              this.answeredScoresImg = res.data.data2[0];
              if (this.answer_count == 5) {
                this.disableNoAnswerButton = false;
                this.isAnswersCompleted = true;

                this.correctAnswersCount = this.answeredScoresImg.filter(
                  function (value) {
                    return value === "Yes";
                  }
                ).length;
              } else {
                this.disableNoAnswerButton = true;
                this.isAnswersCompleted = false;
              }
            } else {
              this.isQuestionAnswered = false
              this.answer_count = 0
              this.answerCounter = 1;

            }
          });
      }
    },
    AnsweredQuestionsPost(picked, correct_ans, answerCounter) {
      var headers = { Authorization: `Token ${this.token}` };
      var postData = {
        picked: picked,
        correct_ans: correct_ans,
        answerCounter: answerCounter,
        live_video_id: this.live_video_id,
        contest_id: this.contest_id,
      };
      axios
        .post(API_URL + "home-win/post-answered-questions/", postData, {
          headers: headers,
        })
        .then(() => {
          this.updateAnsweredQuestions();
          this.haveFunToAnswerQuestion();
        });
    },
    haveFunToAnswerQuestion() {
      if (this.live_video_id != null && this.contest_id != null) {
        var headers = { Authorization: `Token ${this.token}` };
        axios
          .get(
            API_URL +
              "home-win/have-fund-to-answer-questions/" +
              this.contest_id +
              "/" +
              this.live_video_id +
              "/",
            { headers: headers }
          )
          .then((res) => {
            this.haveFundForQuestions = res.data.data;
          });
      }
    },
    updateVideoUserStatusPost() {
      var isEnded = "No";
      if (this.isVideoEnded) {
        isEnded = "Yes";
      }
      var headers = { Authorization: `Token ${this.token}` };
      var postData = {
        current_time: this.videoCurrentTine,
        video_duration: this.videoDuration,
        is_ended: isEnded,
        live_video_id: this.live_video_id,
      };

      axios
        .post(API_URL + "live-video/user-watch-post/", postData, {
          headers: headers,
        })
        .then(() => {
          this.updateVideoUserStatusGet();
        });
    },
    updateVideoUserStatusGet() {
      if (this.live_video_id != null) {
        var headers = { Authorization: `Token ${this.token}` };
        axios
          .get(
            API_URL + "live-video/user-watch-get/" + this.live_video_id + "/",
            { headers: headers }
          )
          .then((res) => {
            if (!res.data.data) {
              var videoDuration = this.$refs.fish[0].duration;
              var videoCurrentTimeGet = res.data[0].current_time;

              if (videoCurrentTimeGet == videoDuration) {

                setTimeout(() => {
                    this.$refs.fish[0].controls = false;
                    this.is_replay = true;
                  }, 1000);
                
              } else {
                
                setTimeout(() => {
                  this.$refs.fish[0].controls = true;
                  this.is_replay = false;
                  }, 1000);
              }

              this.videoCurrentTimeGet = res.data[0].current_time;
            }
          })
          .catch((err) => {
            //console.log('Upcoming err ==', err)
          });
      }
    },
    getLiveShowVoteDetails() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(
          API_URL + "contest/about-reality-tv-shows/" + this.contest_id + "/",
          { headers: headers }
        )
        .then((res) => {
          this.liveShowVote = res.data;
        })
        .catch((err) => {
          //console.log('Upcoming err ==', err)
        });
    },
    getContestantToVote() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "contest/vote-for-contestant/" + this.contest_id + "/", {
          headers: headers,
        })
        .then((res) => {
          this.is_merged = res.data.is_merged;
          this.contestantToVote = res.data.data;
          //console.log('contestantToVote ==', res.data.is_merged)
        })
        .catch((err) => {
          //console.log('Upcoming err ==', err)
        });
    },
    getContestantPhotos() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(
          API_URL + "contest/get-contestant-photos/" + this.contest_id + "/",
          { headers: headers }
        )
        .then((res) => {
          this.contestantPhotos = res.data;
          //console.log('contestantPhotos ==', res.data)
        });
    },
    startContestantVote(contestant_id) {
      const searchInput = parseInt(contestant_id, 10);
      this.constestantId = searchInput;
      this.okCheck(this.constestantId);
    },
    auditionVoting(voteQty) {
      const totalVoteAmount = voteQty * this.voting_cost;
      if (voteQty < this.minimum_vot_qty) {
        alert("Voting amount selected is too low!");
      } else if (totalVoteAmount > this.walletBalanceAmount) {
        alert("You don't enough fund in your wallet to vote!");
      } else {
        var headers = { Authorization: `Token ${this.token}` };
        var postData = {
          vote_qty: voteQty,
          vote_rate: this.voting_cost,
          vote_cost: totalVoteAmount,
          contestant_id: this.constestantId,
        };
        //Note: change to Live Voting
        axios
          .post(API_URL + "vote/camp-voting/", postData, { headers: headers })
          .then(() => {
            alert("Your Voting was Successful!");
            this.updateWallet_ContestantInfo();
          })
          .catch(() => {
            alert("Something went wrong, Try Again!");
            this.updateWallet_ContestantInfo();
          });
      }
    },
    checkFormValidity() {
      const valid = true; //this.$refs.form.checkValidity()
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.voteCount = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      this.auditionVoting(this.voteCount);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    checkWalletBalance() {
      this.$store.dispatch("loadWalletBalance").then((res) => {
        //Convert to Integer
        const wallet_balance = parseInt(res, 10);
        if (wallet_balance > 0) {
          this.isEnableVote = true;
        }
        this.walletBalanceAmount = res;
      });
    },
    okCheck(constestantId) {
      var headers = { Authorization: `Token ${this.token}` };
      //Note: change to Live Vote Balance
      axios
        .get(API_URL + "vote/can-user-vote-balance-camp/" + constestantId, {
          headers: headers,
        })
        .then((res) => {
          this.isEnableVote = res.data.data;
          this.minimum_voting = "Min " + res.data.minimum_voting + " Votes";
          this.minimum_vot_qty = res.data.minimum_voting;
          this.voting_cost = res.data.voting_cost;
        });
      this.$store.dispatch("loadWalletBalance").then((res) => {
        this.walletBalanceAmount = res;
      });
    },
    updateWallet_ContestantInfo() {
      this.$store.dispatch("loadWalletBalance").then((res) => {
        this.walletBalance = "N " + String(res);
        this.getContestantToVote();
        this.Contestant = null;
      });
    },
    watchVideoReward() {
      this.$store.dispatch("loadWatchVideoReward").then((res) => {
        if (res.length > 0) {
          this.videoViewedInfos =
            String(res[0].total_video_end_counts) +
            " Viewed, " +
            String(res[0].total_video_coin_points) +
            " " +
            "DH Coins";
          // console.log('videoViewReward ==', this.videoViewedInfos)
        }
      });
    },
  },
};
</script>
<style scoped>
#show_img {
  /* position: relative; */
  width: 100%;
  z-index: 1;
}
#show_img_ontop {
  position: absolute;
  z-index: 2;
  /* top: 10%; */
  left: 10%;
  right: 10%;
  bottom: 20%;
}
#replay_button {
  width: 100%;
  z-index: 1;
}
#replay_button_ontop {
  position: absolute;
  z-index: 2;
  left: 10%;
  right: 10%;
  top: 5px;
  bottom: 10%;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
}
td {
  text-align: center !important;
}
.flex-container {
  display: inline-flex;
  flex-flow: row;
  justify-content: space-around;
}
.flex-container > div {
  padding: 10px;
}
</style>
