<template>
  <div class="row justify-content-center">
    <div class="col-lg-10 col-md-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Student Sign-up</small>
          </div>
        </div>
        <div
          class="card-body px-lg-5 py-lg-5"
          style="margin-top: -50px !important"
        >
          <form role="form" @submit.prevent="onSignup">
            <div class="row">

              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Upload Passport Photo</label><br>
                  <button class="btn btn-primary p-0" style="padding:7px">
                    <input
                      type="file"
                      accept="image/*"
                      ref="file"
                      @change="selectImage"
                    />
                  </button>
                </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-12">
              <div v-if="previewImage">
                <img class="preview my-3" :src="previewImage" alt="" width="25%" />
              </div>
            </div>

            
              <div class="col-md-12 col-xs-12"></div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  required
                  placeholder="First Name"
                  addon-left-icon="ni ni-hat-3"
                  v-model="model.first_name"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Last Name"
                  required
                  addon-left-icon="ni ni-hat-3"
                  v-model="model.last_name"
                >
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="label"></label>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Other Name"
                  addon-left-icon="ni ni-hat-3"
                  v-model="model.other_name"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <label class="label">Date of Birth</label>
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Date of Birth"
                  required
                  addon-left-icon="ni ni-hat-3"
                  type="date"
                  v-model="model.date_of_birth"
                >
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">  
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Userame"
                  required
                  addon-left-icon="ni ni-single-02"
                  v-model="model.username"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <div
                    class="input-group input-group-merge input-group-alternative mb-3" >
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="ni ni-email-83"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      v-model="model.email"
                      required
                      placeholder="Email"
                      type="email"
                      />
                    </div>
                </div>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative"
                  placeholder="Password"
                  type="password"
                  required
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="model.password"
                >
                </base-input>
              </div>

              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative"
                  placeholder="Repeat Password"
                  type="password"
                  required
                  addon-left-icon="ni ni-lock-circle-open"
                  v-model="model.password2"
                >
                </base-input>
                <div class="text-center font-italic">
                  <span class="text-danger" v-text="comparePasswords"></span>
                </div>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="label">Phone Number</label>
                  <div class="input-group input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-globe-americas"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="234"
                      v-model="model.phone_number"
                      required
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        ><i class="fas fa-phone"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div> 
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label class="label">Whatsapp Number (optional)</label>
                  <div class="input-group input-group-merge">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="fas fa-globe-americas"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="234"
                      v-model="model.whatsapp_number"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        ><i class="fas fa-phone"></i
                      ></span>
                    </div>
                  </div>
                </div>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Age"
                  addon-left-icon="fa fa-transgender"
                  v-model="model.age"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                >
                  <select class="form-control" v-model="model.gender" required >
                    <option value="" selected disabled>Select gender...</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Address"
                  addon-left-icon="fa fa-transgender"
                  v-model="model.address"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="State of Residence"
                  addon-left-icon="fa fa-transgender"
                  v-model="model.state_of_residence"
                >
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="State of Origin "
                  addon-left-icon="fa fa-transgender"
                  v-model="model.state_of_origin"
                >
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <base-input
                  class="input-group-alternative mb-3"
                  placeholder="Country"
                  addon-left-icon="fa fa-transgender"
                  v-model="model.country"
                >
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <label class="label">Seletc your Qualification</label> -->
                <base-input
                  class="input-group-alternative mb-3"
                >
                  <select class="form-control" v-model="model.qualification" required >
                    <option value="" selected disabled>Seletc your Qualification...</option>
                    <option value="(PHD)">(PHD)</option>
                    <option value="(MSC/MA)">(MSC/MA)</option>
                    <option value="(BSC/HND/OND)">(BSC/HND/OND)</option>
                    <option value="(O Level: WAEC, NECO, GCE)">(O Level: WAEC, NECO, GCE)</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <label class="label">Select your Class</label> -->
                <base-input
                  class="input-group-alternative mb-3"
                >
                  <select class="form-control" v-model="model.select_class" required >
                    <option value="" selected disabled>Select your Class...</option>
                    <option value="Online Class">Online Class</option>
                    <option value="Physical Class">Physical Class</option>
                  </select>
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <label class="label">Select your Major Course</label> -->
                <base-input
                  class="input-group-alternative mb-3"
                >
                  <select class="form-control" v-model="model.major_course" required >
                    <option value="" selected disabled>Select your Major Course...</option>
                    <option value="Acting">Acting</option>
                  </select>
                </base-input>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12">
                <!-- <label class="label">Select your Minor Course</label> -->
                <base-input
                  class="input-group-alternative mb-3"
                >
                  <select class="form-control" v-model="model.minor_course" required >
                    <option value="" selected disabled>Select your Minor Course...</option>
                    <option value="Directing">Directing</option>
                    <option value="Continuity">Continuity</option>
                    <option value="Screen Writing">Screen Writing</option>
                    <option value="Make-up">Make-up</option>
                    <option value="Script Writing">Script Writing</option>
                    <option value="Cinematography">Cinematography</option>
                    <option value="Set-design">Set-design</option>
                    <option value="Costume design">Costume design</option>
                    <option value="Music Studio">Music Studio</option>
                    <option value="Editing">Editing</option>
                    <option value="Sound">Sound</option>
                    <option value="Subtitle">Subtitle</option>
                    <option value="Photography">Photography</option>
                    <option value="Production Management">Production Management</option>
                    <option value="Location Management">Location Management</option>
                  </select>
                </base-input>
              </div> <!-- End 2 Cols -->

              <div class="col-md-12 text-center" >
                <div class="form-group" style="padding-top:25px; background-color: #e9ecf1; border-radius:7px;">
                  <base-checkbox class="custom-control-alternative">
                    <p class="text-muted" style="padding:10px !important;">I agree with the <a href="#!">Privacy Policy</a></p>
                  </base-checkbox>
                </div>
              </div>
            
            <div class="col-md-12 col-xs-12" v-if="currentImage">
                <div class="progress" style="width: 100%">
                  <div
                    class="progress-bar progress-bar-success"
                    role="progressbar"
                    :aria-valuenow="progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    :style="{ width: progress + '%' }"
                    >
                      {{ progress }}%
                  </div>
                </div>
              </div>
                
              <div class="col-md-12 text-center">
                <button native-type="submit" class="btn btn-primary btn-block">
                  Register Now!
                </button>
              </div>

              

            </div>
          </form>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import PASSWORD_API_URL from "../common/forget-password-url";

import UploadService from "../services/UploadFilesService";

import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";
// import { configure } from 'vee-validate';

extend("email", email);
extend("required", required);
extend("min", min);

export default {
  name: "student-register",
  // component: { ImageUpload },
  data() {
    return {
      model: {
        username: "",
        first_name: "",
        last_name: "",
        other_name: "",
        email: "",
        password: "",
        password2: "",
        phone_number: "",
        whatsapp_number: 234,
        date_of_birth: "",
        age: "",
        address: "",
        state_of_residence: "",
        state_of_origin: "",
        country: "",
        qualification: "",
        select_class: "",
        gender: '',
        minor_course: '',
        major_course: '',
      },
      href: PASSWORD_API_URL + "/password-reset/",

      currentImage: undefined,
      previewImage: undefined,
      progress: 0,

      //
    };
  },
  computed: {
    comparePasswords() {
      return this.model.password !== this.model.password2
        ? "Passwords do not match"
        : "";
    },
  },
  watch: {
  },
  methods: {
    selectImage() {
      this.currentImage = this.$refs.file.files.item(0);
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
    },
    onSignup() {
      if (
        this.model.username &&
        this.model.password &&
        this.model.first_name &&
        this.model.last_name &&
        this.model.password2 &&
        this.model.email &&
        this.model.phone_number &&
        this.model.other_name &&
        this.model.date_of_birth &&
        this.model.age &&
        this.model.address &&
        this.model.state_of_residence &&
        this.model.state_of_origin &&
        this.model.country &&
        this.model.qualification &&
        this.model.select_class &&
        this.model.gender  &&
        this.model.minor_course  &&
        this.model.major_course &&
        this.currentImage

      ) {
        this.progress = 0;
        // Initialize the form data           
        let formData = new FormData();

      //  Add the form data we need to submit 
        formData.append('username', this.model.username);
        formData.append('password', this.model.password2,);
        formData.append('first_name', this.model.first_name);
        formData.append('last_name', this.model.last_name,);
        formData.append('email', this.model.email);
        formData.append('phone_number', this.model.phone_number,);
        formData.append('other_name', this.model.other_name);
        formData.append('whatsapp_number', this.model.whatsapp_number,);
        formData.append('date_of_birth', this.model.date_of_birth);
        formData.append('age', this.model.age,);
        formData.append('address', this.model.address);
        formData.append('state_of_residence', this.model.state_of_residence,);
        formData.append('state_of_origin', this.model.state_of_origin);
        formData.append('country', this.model.country,);
        formData.append('qualification', this.model.qualification);
        formData.append('select_class', this.model.select_class,);

        formData.append('gender', this.model.gender,);
        formData.append('minor_course', this.model.minor_course);
        formData.append('major_course', this.model.major_course,);

        formData.append('profile_photo', this.currentImage);

        UploadService.upload(formData, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then((res)=>{

          if(res.data.data == true){

            this.progress = 0;
            this.currentImage = undefined;
            this.previewImage = undefined;

            this.$router.push("/student-success-page");

          } else if(res.data.data == "email_exist"){

            alert("Error! Email Exist Alreadly, Kindly use another one!")
            this.progress = 0;
            this.currentImage = undefined;
            this.previewImage = undefined;

          } else if(res.data.data == "username_exist"){

            alert("Error! Username Exist Alreadly, Kindly use another one!")
            this.progress = 0;
            this.currentImage = undefined;
            this.previewImage = undefined;

          } else {

            alert("Error Uploading Registration Details, Retry or Contact Admin!")
            this.progress = 0;
            this.currentImage = undefined;
            this.previewImage = undefined;
          }
        }).catch((error) => {
          alert(`Error Uploading Registration Details! " + ${error}`);
          this.progress = 0;
          this.currentImage = undefined;
        });
        
      } else {
        alert("Please Enter all Required Fields!");
      }
    },
  },
};
</script>
<style scoped>
  .label {
    font-size: 11px !important;
    padding-left: 10px;
    color: rgb(148, 146, 146);
  }
</style>
