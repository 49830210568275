<template>
  <!-- Main content -->
  <!-- <div class="container mt--8 pb-5"> -->

  <div class="row justify-content-center" style="margin-top: -50px !important">
    <div class="col-lg-8 col-md-10" style="margin-top: -50px !important">
      <div
        class="card card-profile bg-secondary mt-5"
        style="padding: 50px !important"
      >
        <div class="row justify-content-center">
          <div class="col-lg-12 col-xs-12" align="center">
            <h4>Login or Signup to Vote for your Contestant</h4>
            <h2>Contestant ID: {{ contestant_id }}</h2>
          </div>
          <div class="col-lg-12 col-xs-12" align="center">
            <a
              v-bind:href="login_contestant"
              class="btn btn-success btn-block"
              style="color: #fff"
              >Login</a
            >
          </div>
          <div class="col-lg-12 col-xs-12" align="center">
            <p style="margin: 20px 0">OR</p>
          </div>
          <div class="col-lg-12 col-xs-12" align="center">
            <a
              :href="signup_contestant"
              class="btn btn-primary btn-block"
              style="color: #fff"
              >Signup</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>
<script>
import router from "../router";

export default {
  name: "Audition",
  data() {
    return {
      model: {
        contestant_id: "",
        login_contestant: "",
        signup_contestant: "",
      },
    };
  },
  created() {
    this.contestant_id = this.$route.query.id;
    ////console.log('this.contestant_id=', this.contestant_id)
    ////console.log('this.user=', this.$store.getters.user)
    ////console.log('this.token=', this.$store.getters.token)
    if (this.$store.getters.token != null) {
      router.push("/vote-contestant/" + this.contestant_id);
    }
    this.login_contestant = "/#/login-contestant/" + this.$route.query.id;
    this.signup_contestant = "/#/signup-contestant/" + this.$route.query.id;
  },
};
</script>
<style></style>
