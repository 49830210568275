<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container-fluid mt--7">
        <!-- Dark table -->
        <div class="row" style="margin-top: 100px; margin-bottom: 60px">
          <div class="col-xl-12 col-lg-12">
            <div class="card bg-default shadow">
              <div class="card-header bg-transparent border-0">
                <h3 class="text-white mb-0">Account Details</h3>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center table-dark table-flush">
                  <!-- Dark table -->
                  <thead class="thead-dark">
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Account Type</th>
                    <th>Bank Name</th>
                  </thead>
                  <tbody>
                    <tr v-for="item in bankDetails" v-bind:key="item.id">
                      <td>{{ item.full_name }}</td>
                      <td>{{ item.account_number }}</td>
                      <td>{{ item.account_type }}</td>
                      <td>{{ item.bank_name }}</td>
                    </tr>
                  </tbody>
                  <!-- Dark table -->
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Dark table -->
      </div>

      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div class="col-xl-12 col-lg-12">
          <stats-card
            title="Submit your Bank Details below to receive payment from DH TV Reality show"
            type="gradient-orange"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <h3><b>Enter your Bank Details:</b></h3>
              <label class="">Account Name</label>
              <base-input
                type="text"
                v-model="full_name"
                placeholder="Enter Account Name"
                input-classes="form-control-alternative"
              />
              <label class="">Account Number</label>
              <base-input
                type="number"
                v-model="account_number"
                placeholder="Enter Account Number"
                input-classes="form-control-alternative"
              />
              <label class="">Account Type</label>
              <base-input
                type="text"
                v-model="account_type"
                placeholder="Enter Account Type e.g Savings, Current, etc"
                input-classes="form-control-alternative"
              />
              <label class="">Bank Name</label>
              <base-input
                type="text"
                v-model="bank_name"
                placeholder="Enter Bank Name"
                input-classes="form-control-alternative"
              />
              <button @click="submitBankDetails()" class="btn btn-primary">
                Submit
              </button>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "add-bank-details",
  components: {},
  data() {
    return {
      full_name: "",
      account_number: null,
      account_type: "",
      bank_name: "",
      bankDetails: [],
    };
  },
  created() {
    this.getBankdetails();
  },
  methods: {
    getBankdetails() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "profile/user-bank-details/", { headers: headers })
        .then((res) => {
          this.bankDetails = res.data;
        });
    },
    submitBankDetails() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      if (this.account_number == "") {
        alert('Please enter Numbers in "Account Number Field"');
      } else {
        var postData = {
          full_name: this.full_name,
          account_type: this.account_type,
          account_number: this.account_number,
          bank_name: this.bank_name,
        };

        axios
          .post(API_URL + "profile/user-bank-details/", postData, {
            headers: headers,
          })
          .then((res) => {
            this.full_name = "";
            this.account_number = "";
            this.account_type = "";
            this.bank_name = "";
            this.getBankdetails();
          });
      }
    },
  },
};
</script>
<style>
th,
td {
  font-size: 14px !important;
}
</style>
