<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container-fluid mt--7" style="margin-top: 0px !important">
        <!-- Dark table -->
        <div class="row">
          <div class="col">
            <div class="card bg-default shadow">
              <div class="card-header bg-transparent border-0">
                <h3 class="text-white mb-0">
                  Schedule for the DhContest TV Show
                </h3>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center table-dark table-flush">
                  <!-- Dark table -->
                  <thead class="thead-dark">
                    <th>Show Title</th>
                    <th>View</th>
                    <th>Status</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </thead>
                  <tbody>
                    <tr v-for="item in upcomingShows" v-bind:key="item.id">
                      <td>{{ item.title }}</td>
                      <td>
                        <a
                          v-if="item.status == 'Start'"
                          :href="'/#/about-reality-tv-shows/' + item.contest_id"
                          id="aBtnColor"
                          class="btn btn-primary"
                          >View Details</a
                        >
                      </td>
                      <td>{{ item.status }}</td>
                      <td>{{ item.start_date | formatdate }}</td>
                      <td>{{ item.end_date | formatdate }}</td>
                    </tr>
                  </tbody>
                  <!-- Dark table -->
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Dark table -->
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "upcoming-shows",
  components: {},
  data() {
    return {
      upcomingShows: "",
    };
  },
  created() {
    this.getUpcomingShows();
  },
  methods: {
    getUpcomingShows() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "contest/upcoming-shows/", { headers: headers })
        .then((res) => {
          this.upcomingShows = res.data;
          //console.log('res ==', this.upcomingShows)
        })
        .catch((err) => {
          //console.log('Upcoming err ==', err)
        });
    },
  },
};
</script>
<style></style>
