<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container-fluid mt--7" style="margin-top: 0px !important">
        <!-- Dark table -->
        <div class="row">
          <div class="col">
            <div class="card bg-default shadow">
              <div class="card-header bg-transparent border-0">
                <h3 class="text-white mb-0">
                  Watch Replay Reality Shows Videos
                </h3>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center table-dark table-flush">
                  <!-- Dark table -->
                  <thead class="thead-dark">
                    <th>Show Title</th>
                    <th>View / Vote Show</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </thead>
                  <tbody>
                    <tr v-for="item in replayVideosList" v-bind:key="item.id">
                      <td><img :src="item.video_thumb" width="75px" /></td>
                      <td>
                        <a
                          :href="
                            '/#/replay-video-details?' +
                            'contest_id=' +
                            item.contest_id +
                            '&' +
                            'live_video_id=' +
                            item.live_video_id
                          "
                          id="aBtnColor"
                          class="btn btn-primary"
                          >View / Vote Show</a
                        >
                      </td>
                      <td>{{ item.start_date | formatdate }}</td>
                      <td>{{ item.end_date | formatdate }}</td>
                    </tr>
                  </tbody>
                  <!-- Dark table -->
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- Dark table -->
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "replay-video-list",
  components: {},
  data() {
    return {
      contest_id: "",
      token: JSON.parse(localStorage.getItem("userData")).token,
      headers: "",
      replayVideosList: "",
    };
  },
  created() {
    this.contest_id = this.$route.params.id;
    this.headers = { Authorization: `Token ${this.token}` };
    this.getListsOfVideos();
  },
  methods: {
    getListsOfVideos() {
      axios
        .get(
          API_URL + "live-video/replay-videos-list/" + this.contest_id + "/",
          { headers: this.headers }
        )
        .then((res) => {
          this.replayVideosList = res.data;
          console.log("liveShowVote ## ==", this.replayVideosList);
        })
        .catch((err) => {
          //console.log('Upcoming err ==', err)
        });
    },
  },
};
</script>
<style></style>
