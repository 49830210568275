<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign up</small>
          </div>
        </div>
        <div
          class="card-body px-lg-5 py-lg-5"
          style="margin-top: -50px !important"
        >
          <form @submit.prevent="onSignup" class="needs-validation" novalidate>
            <base-input
              alternative=""
              class="input-group-alternative mb-3"
              placeholder="Userame"
              addon-left-icon="ni ni-single-02"
              v-model="model.username"
            >
            </base-input>

            <base-input
              class="input-group-alternative mb-3"
              placeholder="First Name"
              addon-left-icon="ni ni-hat-3"
              for="validationCustom01"
              id="validationCustom01"
              required
              v-model="model.first_name"
            >
            </base-input>

            <base-input
              class="input-group-alternative mb-3"
              placeholder="Last Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.last_name"
            >
            </base-input>

            <base-input
              alternative=""
              class="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
            >
            </base-input>

            <base-input
              class="input-group-alternative invalid-feedback"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Repeat Password"
              type="password"
              required
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password2"
            >
            </base-input>

            <div class="form-group">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-globe-americas"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  placeholder="234"
                  v-model="model.phone_number"
                  required
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="fas fa-phone"></i
                  ></span>
                </div>
              </div>
            </div>

            <base-input
              class="input-group-alternative"
              readonly="readonly"
              required
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.referralCode"
            >
            </base-input>

            <!-- <div class="text-muted font-italic">
                            <small>password strength: <span class="text-success font-weight-700">strong</span></small>
                        </div> -->
            <div class="text-center font-italic">
              <span class="text-danger" v-text="comparePasswords"></span>
            </div>

            <div class="row my-4">
              <div class="col-12">
                <base-checkbox class="custom-control-alternative">
                  <span class="text-muted"
                    >I agree with the <a href="#!">Privacy Policy</a></span
                  >
                </base-checkbox>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary">
                Create account
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light">
            <small>Forgot password?</small>
          </a>
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Login into your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PASSWORD_API_URL from "../common/forget-password-url";

export default {
  name: "referral-register",
  data() {
    return {
      model: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password2: "",
        phone_number: "",
        referralCode: this.$route.params.code,
      },
      href: PASSWORD_API_URL + "/password-reset/",
    };
  },
  computed: {
    comparePasswords() {
      return this.model.password !== this.model.password2
        ? "Passwords do not match"
        : "";
    },
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        //console.log('token 1=')
        this.$router.push("/dashboard");
      }
    },
    token(value) {
      //console.log('token 2=', value)
    },
  },
  created() {
    this.needValidation();
  },
  methods: {
    onSignup() {
      if (
        this.model.username &&
        this.model.password &&
        this.model.first_name &&
        this.model.last_name &&
        this.model.password2 &&
        this.model.email &&
        this.model.referralCode &&
        this.model.phone_number
      ) {
        //console.log('username', this.model.username, 'password', this.model.password,  'referralCode', this.model.referralCode)
        this.$store.dispatch("signUserUpReferral", {
          username: this.model.username,
          password: this.model.password,
          email: this.model.email,
          first_name: this.model.first_name,
          last_name: this.model.last_name,
          referralCode: this.model.referralCode,
          phone_number: this.model.phone_number,
        });
      } else {
        alert("Please Enter all Required Fields!");
      }
    },
    needValidation() {
      // (function() {
      //   'use strict';
      window.addEventListener(
        "load",
        function () {
          // Fetch all the forms we want to apply custom Bootstrap validation styles to
          var forms = document.getElementsByClassName("needs-validation");
          //console.log('forms', forms)
          // Loop over them and prevent submission
          var validation = Array.prototype.filter.call(forms, function (form) {
            //console.log('is validation', validation)
            form.addEventListener(
              "submit",
              function (event) {
                if (form.checkValidity() === false) {
                  event.preventDefault();
                  event.stopPropagation();
                }
                form.classList.add("was-validated");
              },
              false
            );
          });
        },
        false
      );
      // })();
    },
  },
};
</script>
<style></style>
