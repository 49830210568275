<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-3"></div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Top up your Wallet"
            type="gradient-orange"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <form @submit.prevent="submitPayment">
                <h3><b>Select Amount</b></h3>
                <div class="form-group">
                  <select
                    class="form-control"
                    id="select_amount"
                    name="select_amount"
                    v-on:change="onSelectChange($event)"
                    v-model="key"
                    required="yes"
                  >
                    <option value="" selected>Select amount...</option>
                    <!-- <option value="100">N100</option> -->
                    <!-- <option value="200">N200</option> -->
                    <option value="500">N500</option>
                    <option value="1000">N1,000</option>
                    <option value="2000">N2,000</option>
                    <option value="5000">N5,000</option>
                    <option value="10000">N10,000</option>
                    <option value="20000">N20,000</option>
                  </select>
                </div>
                <button type="submit" class="btn btn-primary btn-block">
                  Fund your Wallet
                </button>
                <img
                  src="img/flutterwave-logo.jpg"
                  width="100%"
                  style="margin-top: 10px"
                />
              </form>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3"></div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- Dark table -->
      <div class="row">
        <div class="col">
          <div class="card bg-default shadow">
            <div class="card-header bg-transparent border-0">
              <h3 class="text-white mb-0">Transaction History</h3>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-dark table-flush">
                <!-- Dark table -->
                <thead class="thead-dark">
                  <th>Reference Number</th>
                  <th>Amount Paid</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Submit Payment Complain</th>
                </thead>
                <tbody>
                  <tr v-for="item in historyList" v-bind:key="item.id">
                    <td>{{ item.payment_refernce }}</td>
                    <td>N {{ item.amount_paid }}</td>
                    <td>{{ item.is_payment_confirmed }}</td>
                    <td>{{ item.payment_date | formatdate }}</td>
                    <td>
                      <Button
                        v-if="item.is_payment_confirmed == 'Pending'"
                        class="btn btn-success"
                        @click="postPaymentComplain(item.payment_refernce)"
                        >Submit Complain</Button
                      >
                      <span
                        v-if="item.is_payment_confirmed == 'Confirmed'"
                        style="padding: 10px 50px; border-radius: 5px"
                        ><h4 style="color: #fff !important">Confirmed</h4></span
                      >
                    </td>
                  </tr>
                </tbody>
                <!-- Dark table -->
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Dark table -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";
import router from "../router";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "wallet",
  components: {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("userData")).token,
      key: "",
      select_amount: null,
      historyList: [],
      contestant_id: "",
      vote_contestant_id: "",
    };
  },
  created() {
    this.contestant_id = this.$route.query.id;
    if (this.contestant_id == undefined) {
      this.vote_contestant_id = null;
    } else {
      this.vote_contestant_id = this.contestant_id;
    }
    this.getPaymentHistory();
  },
  methods: {
    submitPayment() {
      if (this.select_amount != null) {
        this.$router.push(
          "/wallet-payment?" +
            "select_amount=" +
            this.select_amount +
            "&" +
            "vote_contestant_id=" +
            this.vote_contestant_id
        );
      } else {
        alert("Please select a funding amount!");
      }
    },
    onSelectChange(event) {
      this.select_amount = event.target.value;
      ////console.log(event.target.value)
    },
    getPaymentHistory() {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(API_URL + "wallet/payment-history/", { headers: headers })
        .then((res) => {
          this.historyList = res.data;
          //console.log('PaymentHistory ==', res.data)
        });
    },
    postPaymentComplain(payment_ref) {
      console.log("payment_ref ==", payment_ref);
      router.push("/payment-complain/" + payment_ref);
    },
  },
};
</script>
<style></style>
