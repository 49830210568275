<template>
  <!-- Main content -->
  <!-- <div class="container mt--8 pb-5"> -->
  <div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <body class="text-left">
      <div class="app-landing-wrap landing_wrap">
        <div id="landing_wrap" class="landing-indigo-light">
          <!--=============== Header start ================-->
          <div
            class="main-header header-fixed-default"
            id="home-header"
            style="
              background-color: #776dcd;
              padding-top: 10px !important;
              padding-bottom: 0px !important;
              padding-left: 10px !important;
              padding-right: 10px !important;
            "
          >
            <nav
              class="
                navbar
                w-100
                navbar-expand-lg navbar-transparent
                bg-transparent
              "
              style="background-color: #776dcd !important"
            >
              <div class="logo">
                <img
                  src="./../assets/images/logo.png"
                  alt=""
                  style="width: 70% !important; height: 70% !important"
                />
              </div>

              <div style="margin: auto"></div>

              <div class="header-part-right">
                <!-- Navbar -->
                <base-nav
                  class="navbar-top navbar-horizontal navbar-dark"
                  containerClasses="px-4 container"
                  expand
                >
                  <template v-slot="{ closeMenu }">
                    <!-- Collapse header -->
                    <div class="navbar-collapse-header d-md-none">
                      <div class="row">
                        <div class="col-6 collapse-close">
                          <button
                            type="button"
                            @click="closeMenu"
                            class="navbar-toggler"
                            aria-label="Toggle sidenav"
                          >
                            <span></span>
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Navbar items -->
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item" v-if="!LoginNavGuard">
                        <router-link class="nav-link nav-link-icon" to="/">
                          <i class="ni ni-circle-08"></i>
                          <span class="nav-link-inner--text">Home</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="!LoginNavGuard">
                        <router-link
                          class="nav-link nav-link-icon"
                          to="/register"
                        >
                          <i class="ni ni-circle-08"></i>
                          <span class="nav-link-inner--text">Register</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="!LoginNavGuard">
                        <router-link class="nav-link nav-link-icon" to="/login">
                          <i class="ni ni-key-25"></i>
                          <span class="nav-link-inner--text">Login</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="LoginNavGuard">
                        <router-link
                          class="nav-link nav-link-icon"
                          to="/dashboard"
                        >
                          <i class="ni ni-key-25"></i>
                          <span class="nav-link-inner--text">Dashboard</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="LoginNavGuard">
                        <a class="nav-link nav-link-icon">
                          <i class="ni ni-user-run"></i>
                          <span @click="onLogout" class="nav-link-inner--text"
                            >Logout</span
                          >
                        </a>
                      </li>
                    </ul>
                  </template>
                </base-nav>
              </div>
            </nav>
          </div>

          <!--=============== Header End ================-->

          <div class="main-content-wrap">
            <section
              id="intro-wrap"
              class="intro-nine-Wrap bg-primary text-left text-white"
            >
              <div class="container">
                <div class="row" >
                  <div class="col-md-8 intro-nine-LeftSection pb-3">
                    <h3
                      class="font-weight-bold text-white mb-3 t-shadow"
                    >
                      Welcome to DHCONTEST FILM ACADEMY 
                    </h3>
                  </div>
                  <p>
                    
                  </p>
                </div>
              </div>
              <div class="overlay"></div>
            </section>

            <!-- start::Contact us DH Contest -->
            <section class="left-image-wrap p-t-b-80">
              <div class="container">
                <div class="row">
                  <div
                    class="col-md-12 mb-4"
                    style="margin-top: -30px !important"
                  >
                    <!-- :data-aos="" -->
                    <div>
                      <p align="center"><img src="./../assets/Film-Academy.jpg" width="75%" />
                      <br> <br>
                      <button class="btn btn-primary btn-flat" @click="goRegister()" ><b> Click here to Register Now!</b></button>
                      </p>
                    </div>
                  </div>
                  <!-- end of col6 row -->
                </div>
              </div>
            </section>
            <!-- end::Contact us DH Contest -->

            <!-- <section> -->
            <footer class="footer px-4" style="margin-top: -100px !important">
              <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-6">
                  <div class="copyright text-center text-lg-left text-muted">
                    © {{ year }}
                    <a
                      href="https://dhcontest.com"
                      class="font-weight-bold ml-1"
                      >DH Contest</a
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <ul
                    class="
                      nav nav-footer
                      justify-content-center justify-content-lg-end
                    "
                  >
                    <li class="nav-item">
                      <router-link to="/" class="nav-link">
                        <span>Home</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/about-us" class="nav-link">
                        <span>About Us</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/contact-us" class="nav-link">
                        <span>Contact Us</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
            <!-- </section> -->
          </div>
        </div>
      </div>

      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/jquery-3.3.1.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/bootstrap.bundle.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/perfect-scrollbar.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/slick.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/scroll.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/slick.script.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/aos.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/script.min.js"
      ></script>
    </body>
  </div>
</template>
<script>
/* eslint-disable */
import "../assets/styles/vendor/slick.css";
import "../assets/styles/vendor/slick-theme.css";
import "../assets/styles/vendor/aos.css";
import "../assets/styles/vendor/perfect-scrollbar.css";

import "./../assets/js/vendor/jquery-3.3.1.min.js";
import "./../assets/js/vendor/aos.js";
import "./../assets/js/es5/script.min.js";

import axios from "axios";
import API_URL from "./../common/config";

export default {
  name: "film-academy",
  data() {
    return {
      year: new Date().getFullYear(),
      LoginNavGuard: false,
      contacttUsDetails: null,
    };
  },
  created() {

  },
  methods: {
    goRegister(){
      this.$router.push("/student-register");
    }
  },
};
</script>
<style scoped src="@/assets/styles/css/themes/lite-purple.min.css"></style>
