<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Wallet balance"
            type="gradient-red"
            :sub-title="walletBalance"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-success mr-2">
                <router-link to="/wallet">
                  <i class="ni ni-bold-right"></i>
                  <span> Top Up</span>
                </router-link>
              </span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Referral Bonus"
            type="gradient-orange"
            :sub-title="totalReferralBonusEarned"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <!-- <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                            <span class="text-nowrap">Since last month</span> -->
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Coins Bonus Wallet"
            type="gradient-green"
            :sub-title="videoViewReward"
            icon="ni ni-satisfied"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <span class="text-nowrap">{{ videoViewedInfos }}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
            title="Videos Views"
            type="gradient-info"
            :sub-title="videoViewCount"
            icon="ni ni-tv-2"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <!-- <span class="text-success mr-2">
                              <router-link to="/dashboard">
                                  <i class="ni ni-bold-right"></i>
                                  <span >Viewed Videos</span>
                              </router-link>
                            </span> -->
              <!-- <span class="text-nowrap">Since last month</span> -->
            </template>
          </stats-card>
        </div>
      </div>
      <div v-if="is_enable_video_dashboard">
        <br />
        <br />
        <hr style="border-color: #fff; margin-top: 0px; margin-bottom: 0px" />
        <LiveShowVote v-bind:contest_id="contest_id" />
      </div>
    </base-header>

    <!--Search-->
    <div class="container-fluid" style="margin-top: -30px">
      <div class="row" v-if="is_search_ready">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col-xl-12">
                <h5 class="h3 text-white mb-0">
                  Search and Vote for your Candidate(s) - Audition
                </h5>
              </div>
              <div class="col-xl-12">
                <div class="form-group mb-0">
                  <form @submit.prevent="submitSearch">
                    <base-input
                      placeholder="Enter Contestant's ID"
                      class="input-group-alternative"
                      type="number"
                      addon-right-icon="fas fa-search"
                      v-model="search"
                    >
                    </base-input>
                    <button type="submit" class="btn btn-primary">
                      Search
                    </button>
                    <p v-if="searchLoading" style="margin-top:20px; color: white;">Searching! Please wait...</p>
                  </form>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- End Search-->

      <!--Search Results-->
      <div class="row mt-5">
        <div class="col-xl-6">
          <div class="card" v-for="item in Contestant" v-bind:key="item.id">
            <div class="card-header">
              <h5 class="h3 mb-0">Search Result (s)</h5>
            </div>
            <div class="card-header d-flex align-items-center">
              <div class="d-flex align-items-center">
                <a v-if="item.photo">
                  <img :src="item.photo" class="avatar" />
                </a>
                <div class="mx-3">
                  <a class="text-dark font-weight-600 text-sm"
                    >{{ item.first_name }} {{ item.last_name }}</a
                  >
                  <small class="d-block text-muted"
                    >Contestant ID: {{ item.contestant_id }}</small
                  >
                </div>
              </div>
              <div class="text-right ml-auto" v-if="isEnableVote">
                <b-button
                  v-b-modal.modal-prevent-closing
                  class="btn btn-sm btn-success btn-icon"
                >
                  <span class="btn-inner--icon"
                    ><i class="ni ni-fat-add"></i
                  ></span>
                  <span class="btn-inner--text">Vote</span>
                </b-button>
              </div>
              <div class="text-right ml-auto" v-else-if="!isEnableVote">
                <b-button class="btn btn-sm btn-danger btn-icon">
                  <span class="btn-inner--icon"
                    ><i class="ni ni-fat-add"></i
                  ></span>
                  <span class="btn-inner--text">Add Fund to Vote</span>
                </b-button>
              </div>
            </div>
            <div class="card-body">
              <div v-if="item.photo">
                <img
                  :src="item.photo"
                  alt="Image placeholder"
                  class="img-fluid rounded"
                />
              </div>
              <br /><br />
              <p class="" style="padding: 0px !important">
                <span
                  style="marign-bottom: 0px !important"
                  v-html="item.about_contestant"
                ></span>
              </p>
              <div class="row align-items-center my-3 pb-3 border-bottom">
                <div class="col-sm-6">
                  <div class="icon-actions">
                    <a href="#" class="like active">
                      <i class="ni ni-like-2"></i>
                      <span class="text-muted"> {{ item.vote_count }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--End Search Results-->

      <!--All Contestants-->
      <div class="row mt-5">
        <div class="col-xl-12"><h2>Top Voted Contestants</h2></div>
        <div class="col-xl-12" v-if="!is_search_ready" align="center">
          <p align="center">
            <FacebookLoader :color="'#54f1d2'" /><FacebookLoader />
          </p>
        </div>
        <div
          class="col-md-4 col-xl-4"
          v-for="item in allContestants"
          v-bind:key="item.id"
        >
          <div class="card">
            <div
              style="
                background-color: #172b4c !important;
                color: #fff !important;
              "
              class="card-header dark"
            >
              <h5 class="h3 mb-0" style="color: #fff !important">
                Contest: {{ item.contest_title }}
              </h5>
            </div>
            <div class="card-header d-flex align-items-center">
              <div class="d-flex align-items-center">
                <a v-if="item.photo">
                  <img :src="item.photo" class="avatar" />
                </a>
                <div class="mx-3">
                  <a class="text-dark font-weight-600 text-sm"
                    >{{ item.first_name }} {{ item.last_name }}</a
                  >
                  <small class="d-block text-muted"
                    >Contestant ID: {{ item.contestant_id }}</small
                  >
                </div>
              </div>
              <div class="text-right ml-auto" v-if="item.can_user_vote">
                <b-button
                  v-b-modal.modal-prevent-closing2
                  class="btn btn-sm btn-success btn-icon"
                  v-on:click="startContestantVote(item.contestant_id)"
                >
                  <span class="btn-inner--icon"
                    ><i class="ni ni-fat-add"></i
                  ></span>
                  <span class="btn-inner--text">Vote</span>
                </b-button>
              </div>
              <div class="text-right ml-auto" v-else-if="!item.can_user_vote">
                <b-button class="btn btn-sm btn-danger btn-icon">
                  <span class="btn-inner--icon"
                    ><i class="ni ni-fat-add"></i
                  ></span>
                  <span class="btn-inner--text">Add Fund to Vote</span>
                </b-button>
              </div>
            </div>
            <div class="card-body">
              <div v-if="item.photo">
                <img
                  :src="item.photo"
                  alt="Image placeholder"
                  class="img-fluid rounded"
                />
              </div>
              <br /><br />
              <div
                class=""
                style="padding: 0px !important; font-size: 10px !important"
              >
                <span
                  style="
                    marign-bottom: 0px !important;
                    font-size: 10px !important;
                  "
                  v-html="item.about_contestant"
                ></span>
              </div>
              <div class="row align-items-center my-3 pb-3 border-bottom">
                <div class="col-sm-6">
                  <div class="icon-actions">
                    <a href="#" class="like active">
                      <i class="ni ni-like-2"></i>
                      <span class="text-muted"> {{ item.vote_count }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
      <!--End All Contestants-->
    </div>
    <!-- Start Voting Model -->
    <div v-for="item in Contestant" v-bind:key="item.id">
      <!-- hide-backdrop -->
      <b-modal
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        id="modal-prevent-closing"
        ref="modal"
        title="Vote Now!"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <div class="d-block">
          <p>
            <b>Vote for Contestant:</b> {{ item.first_name }}
            {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id }}<br />Vote
            Cost: N{{ voting_cost }}/vote
          </p>
        </div>

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            :label="minimum_voting"
            label-for="name-input"
            invalid-feedback="Vote is required"
          >
            <b-form-select
              id="name-input"
              v-model="voteCount"
              :options="variants"
              :state="nameState"
              required
            ></b-form-select>
            <!-- <b-form-input
                  id="name-input"
                  v-model="name"
                  :state="nameState"
                  required
                ></b-form-input> -->
          </b-form-group>
        </form>
      </b-modal>
    </div>
    <!-- start Modal 2 -->
    <div v-for="item in allContestants" v-bind:key="item.id">
      <!-- hide-backdrop -->
      <b-modal
        v-if="item.contestant_id == constestant_Id"
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        id="modal-prevent-closing2"
        ref="modal"
        title="Vote Now!"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk2"
      >
        <div class="d-block">
          <p>
            <b>Vote for Contestant:</b> {{ item.first_name }}
            {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id }}<br />Vote
            Cost: N{{ voting_cost }}/vote
          </p>
        </div>

        <form ref="form" @submit.stop.prevent="handleSubmit2">
          <b-form-group
            :state="nameState"
            :label="minimum_voting"
            label-for="name-input"
            invalid-feedback="Vote is required"
          >
            <b-form-select
              id="name-input"
              v-model="voteCount"
              :options="variants"
              :state="nameState"
              required
            ></b-form-select>
            <!-- <b-form-input
                  id="name-input"
                  v-model="name"
                  :state="nameState"
                  required
                  ></b-form-input> -->
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import IMG_URL from "./../common/img_url";
import REFERRAL_API_URL from "./../common/url-config";
import LiveShowVote from "./../users/LiveShowVoteForDashboard";
import Vue from "vue";
import moment from "moment";
import { FacebookLoader, EllipsisLoader, RippleLoader } from "vue-spinners-css";

import "bootstrap-vue";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});
export default {
  name: "user-dashboard",
  components: {
    LiveShowVote,
    EllipsisLoader,
    RippleLoader,
  },
  data() {
    return {
      token: JSON.parse(localStorage.getItem("userData")).token,
      search: "",
      Contestant: [],
      constestantId: "",
      constestant_Id: "",
      walletBalance: "",
      walletBalanceAmount: "",
      name: "",
      nameState: null,
      submittedNames: [],
      voteCount: "",
      variants: [1, 2, 4, 5, 10, 20, 30, 40, 50, 60, 80, 100],
      headerBgVariant: "primary",
      headerTextVariant: "dark",
      isEnableVote: "",
      voting_cost: "",
      minimum_voting: "",
      minimum_vot_qty: "",
      IMG_URL: IMG_URL,
      allContestants: [],
      videoViewCount: "0",
      videoViewReward: "0",
      videoViewedInfos: "",
      totalReferralBonusEarned: "0",
      contest_id: "",
      is_enable_video_dashboard: false,
      is_search_ready: false,
      searchLoading: false,
    };
  },
  created() {
    this.$store.dispatch("loadWalletBalance").then((res) => {
      ////console.log('res', res)
      this.walletBalance = "N " + String(res);
    }),
      this.getAllContestantsAuditions()
      this.watchVideoCount();
    this.watchVideoReward();
    this.detReferralBonusBalance();
    this.showVideoOnDashbaord();
  },
  mounted() {
    //this.walletBalance = 'N ' + String(this.$store.getters.getWalletBalance)
  },
  methods: {
    getAllContestantsAuditions() {
      var headers = { Authorization: `Token ${this.token}` };

      axios
        .get(API_URL + "contest/all-contestants-auditions/", {
          headers: headers,
        })
        .then((res) => {
          this.is_search_ready = true
          this.allContestants = res.data
        });
    },
    startContestantVote(contestant_id) {
      //var headers = {'Authorization': `Token ${this.token}`}
      const searchInput = parseInt(contestant_id, 10);
      this.constestant_Id = searchInput;
      this.okCheck(this.constestant_Id);
    },
    auditionVoting(voteQty) {
      //console.log('voteQty', voteQty)
      //console.log('this.minimum_vot_qty', this.minimum_vot_qty, this.voting_cost, this.walletBalanceAmount)
      const totalVoteAmount = voteQty * this.voting_cost;
      ////console.log('totalVoteAmount', totalVoteAmount, 'id: ', this.constestantId)
      ////console.log('totalVoteAmount', totalVoteAmount, 'id: ', this.constestantId)
      if (voteQty < this.minimum_vot_qty) {
        alert("Voting amount selected is too low!");
      } else if (totalVoteAmount > this.walletBalanceAmount) {
        alert("You don't enough fund in your wallet to vote!");
      } else {
        var token = JSON.parse(localStorage.getItem("userData")).token;
        var headers = { Authorization: `Token ${token}` };

        if (this.constestantId) {
          var constestantId = this.constestantId;
        } else {
          //console.log('constestant_Id', this.constestant_Id)
          var constestantId = this.constestant_Id;
        }

        var postData = {
          vote_qty: voteQty,
          vote_rate: this.voting_cost,
          vote_cost: totalVoteAmount,
          contestant_id: constestantId,
        };
        //console.log('postData', postData)
        axios
          .post(API_URL + "vote/audition-voting/", postData, {
            headers: headers,
          })
          .then(() => {
            alert("Your Voting was Successful!");
            this.updateWallet_ContestantInfo();
          })
          .catch(() => {
            alert(
              "Something went wrong or You don't enough fund in your wallet to vote! Try Again!"
            );
            this.updateWallet_ContestantInfo();
          });
      }
    },
    submitSearch() {
      var headers = { Authorization: `Token ${this.token}` };
      const searchInput = parseInt(this.search, 10);
      this.searchLoading = true
      axios
        .get(API_URL + "contest/get-contestants/" + searchInput, {
          headers: headers,
        })
        .then((res) => {
          ////console.log('getContestants', res.data)
          this.Contestant = res.data;
          this.constestantId = res.data[0].contestant_id;
          ////console.log('this.constestantId 1', this.constestantId)
          this.okCheck(res.data[0].contestant_id);
          this.searchLoading = false
        })
        .catch(() => {
          //alert("You have entered Invalid Contestant ID");
        });
    },

    checkFormValidity() {
      const valid = true; //this.$refs.form.checkValidity()
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.voteCount = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      ////console.log('voteCount', this.voteCount)
      this.auditionVoting(this.voteCount);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    okCheck(constestantId) {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(API_URL + "vote/can-user-vote-balance/" + constestantId, {
          headers: headers,
        })
        .then((res) => {
          ////console.log('okCheck ==', res.data.data)
          this.isEnableVote = res.data.data;
          this.minimum_voting = "Min " + res.data.minimum_voting + " Votes";
          this.minimum_vot_qty = res.data.minimum_voting;
          this.voting_cost = res.data.voting_cost;
        });

      this.$store.dispatch("loadWalletBalance").then((res) => {
        this.walletBalanceAmount = res;
      });
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit2();
    },
    handleSubmit2() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //console.log('this.voteCount', this.voteCount)
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      this.auditionVoting(this.voteCount);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing2");
      });
    },
    updateWallet_ContestantInfo() {
      if (this.search) {
        this.submitSearch();
      }
      this.getAllContestantsAuditions();

      this.$store.dispatch("loadWalletBalance").then((res) => {
        this.walletBalance = "N " + String(res);
      });
    },
    watchVideoCount() {
      this.$store.dispatch("loadWatchVideoCount").then((res) => {
        this.videoViewCount = String(res.data);
      });
    },
    // Cross-check // res[0]
    watchVideoReward() {
      this.$store.dispatch("loadWatchVideoReward").then((res) => {
        this.videoViewReward = "N " + String(res[0].total_video_points_rewards);
        this.videoViewedInfos =
          String(res[0].total_video_counts) +
          " " +
          String(res[0].total_video_end_counts) +
          " " +
          String(res[0].total_video_coin_points) +
          " " +
          "DH Coins";
        //console.log('videoViewReward ==', this.videoViewedInfos)
      });
    },
    detReferralBonusBalance() {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(API_URL + "referral/referral-balance/", { headers: headers })
        .then((res) => {
          this.totalReferralBonusEarned =
            "N " + String(res.data[0].total_referal_bonuses);
        });
    },
    showVideoOnDashbaord() {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(API_URL + "contest/show-video-on-dashboard/", { headers: headers })
        .then((res) => {
          if (res.data.length == 1) {
            this.is_enable_video_dashboard = true;
            this.contest_id = res.data[0].contest_id;
            this.updateWallet_ContestantInfo();
            //console.log('show-video-on-dashboard ==', res.data)
          } else {
            //console.log('Disable-video-on-dashboard ==')
            this.is_enable_video_dashboard = false;
          }
        });
    },
  },
};
</script>
<style scoped>
b-button {
  background-color: blueviolet !important;
}
</style>
