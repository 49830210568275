<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-3"></div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Top up your Wallet"
            type="gradient-orange"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <form>
                <h3 align="center">
                  <b>N{{ amount }}</b>
                </h3>
                <div class="checkout-form" align="center">
                  <script
                    type="application/javascript"
                    defer
                    src="https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
                  ></script>
                  <button
                    type="button"
                    v-on:click="payWithRave()"
                    name="bton2"
                    id="bton2"
                    class="btn btn-primary btn-block"
                    style="
                      color: #fff;
                      padding: 5px;
                      width: 200px;
                      font-size: 20px;
                    "
                  >
                    <b> Pay Now!</b>
                  </button>
                </div>
                <img
                  src="img/flutterwave-logo.jpg"
                  width="100%"
                  style="margin-top: 10px"
                />
              </form>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3"></div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import router from "../router";
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "wallet-payment",
  components: {},
  data() {
    return {
      amount: "",
      public_key: "",
      email: "",
      phone: "",
      random_ref: "",
      payment_id: "",
      transaction_public_id: "",
      reference: "",
      status: "",
      charged_amount: "",
      currency: "NGN",
    };
  },
  created() {
    this.amount = this.$route.query.select_amount;
    this.vote_contestant_id = this.$route.query.vote_contestant_id;
    //console.log('this.amount',this.amount)
    //console.log('this.vote_contestant_id',this.vote_contestant_id)
  },
  methods: {
    payWithRave() {
      this.generatePaymentDetails().then((res) => {
        //console.log('res top ==', res)
        this.public_key = res.data.public_key;
        this.email = res.data.data[0].email;
        this.phone = res.data.data[0].customer_phone;
        this.random_ref = res.data.data[0].payment_refernce;
        this.payment_id = res.data.data[0].payment_id;
        this.transaction_public_id = res.data.data[0].transaction_public_id;
        var vote_contestant_id = res.data.data[0].vote_contestant_id;

        //console.log('vote_contestant_id res 1==', vote_contestant_id)
        //console.log('res.data.public_key ==', res.data.public_key)
        //console.log('res.data.data[0].payment_id ==', res.data.data[0].payment_id)

        // Set time Out to allow the Data to be catch from the API
        setTimeout(function () {
          //console.log('this.email ==', res)

          var public_key = res.data.public_key;
          var email = res.data.data[0].email;
          var phone = res.data.data[0].customer_phone;
          var random_ref = res.data.data[0].payment_refernce;
          var payment_id = res.data.data[0].payment_id;
          var amount_paid = res.data.data[0].amount_paid;
          var transaction_public_id = res.data.data[0].transaction_public_id;
          var vote_contestant_id = res.data.data[0].vote_contestant_id;

          //console.log('vote_contestant_id res 2==', vote_contestant_id)
          // Start - Running After time Out
          var x = getpaidSetup({
            PBFPubKey: public_key,
            customer_email: email,
            amount: amount_paid,
            customer_phone: phone,
            currency: "NGN",
            txref: random_ref,
            //custom_title: "Dh Contest",
            //custom_logo: "{{ logo }}",
            meta: [
              {
                metaname: "OnlineShopping",
                metavalue: "Checkout",
              },
            ],
            onclose: function () {},
            callback: function (response) {
              //var transaction_public_id = this.transaction_public_id
              var reference = response.tx.txRef;
              var status = response.tx.status;
              var charged_amount = response.tx.amount;
              var currency = response.tx.currency;
              //console.log("This is the response returned after a charge", response);
              //alert('success. Click Button To Continue ' + reference);
              if (
                response.tx.chargeResponseCode == "00" ||
                response.tx.chargeResponseCode == "0"
              ) {
                router.push(
                  "/payment-success?" +
                    "public_id=" +
                    transaction_public_id +
                    "&" +
                    "reference=" +
                    reference +
                    "&" +
                    "status=" +
                    status +
                    "&" +
                    "charged_amount=" +
                    charged_amount +
                    "&" +
                    "currency=" +
                    currency +
                    "&" +
                    "vote_contestant_id=" +
                    vote_contestant_id
                );
              } else {
                // redirect to a failure page.
              }
              x.close();
            },
          });
          // End - Running After time Out
        }, 3000);
      });
    },
    generatePaymentDetails() {
      // router.push("/payment-success?" + 'public_id=' + this.transaction_public_id + "&" + 'reference=' + this.reference + "&" + 'status=' + this.status + "&" + 'charged_amount=' + this.charged_amount + "&" + 'currency=' + this.currency);

      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      const amount = parseInt(this.amount, 10);
      //console.log('amount', amount)
      //console.log('generatePay vote_contestant_id', this.vote_contestant_id)
      return axios.get(
        API_URL +
          "wallet/generate-payment/" +
          amount +
          "/" +
          this.vote_contestant_id +
          "/",
        { headers: headers }
      );
    },
  },
};
</script>
<style></style>
