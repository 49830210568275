<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-2 col-lg-2"></div>
        <div class="col-xl-8 col-lg-8">
          <stats-card
            title="Send Payment Complain if you are Debited but the Payment is not showing on your Dashbaord"
            type="gradient-orange"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <form>
                <h4><b>Payment Reference No.</b></h4>
                <div class="form-group">
                  <input
                    class="form-control"
                    readonly="readonly"
                    :value="payment_ref"
                    id="payment-id"
                  />
                </div>
                <a
                  :href="webToChatUrl"
                  class="btn btn-primary btn-block"
                  style="color: #fff"
                  target="_blank"
                  >Send to Whatsapp if you're on Smart Phone</a
                >
                <button
                  type="submit"
                  @click="Copy()"
                  class="btn btn-success btn-block"
                >
                  Copy & Send to Whatsapp if you're on Computer
                </button>
                <button
                  type="submit"
                  @click="goBackToWallet()"
                  class="btn btn-warning btn-block"
                >
                  Cancel & go back to Wallet
                </button>
              </form>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-2 col-lg-2"></div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "wallet-payment-complain",
  components: {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("userData")).token,
      payment_ref: "",
      webToChatUrl: "",
    };
  },
  created() {
    (this.payment_ref = this.$route.params.payment_ref),
      this.postPaymentComplain();
  },
  methods: {
    postPaymentComplain() {
      console.log("payment_ref 2==", this.payment_ref);
      this.webToChatUrl =
        "https://wa.me/2348157043034?text=Hello! Kindly Comfirm this payment - Payment Reference No: " +
        this.payment_ref;
    },
    goBackToWallet() {
      this.$router.push("/wallet");
    },
    Copy() {
      var Url = document.getElementById("payment-id");
      Url.value = this.payment_ref;
      Url.focus();
      Url.select();
      document.execCommand("Copy");
      alert("Payment Referance No. was Successfully Copied");
    },
  },
};
</script>
<style></style>
