<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div class="col-xl-12 col-lg-12">
          <stats-card
            title="Total Referral Bonus Earned"
            type="gradient-green"
            :sub-title="totalReferralBonusEarned"
            icon="ni ni-satisfied"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <h5>
                &nbsp;&nbsp; Total Bonus Requested:
                <b>N{{ totalBonusRequested }}</b>
              </h5>
              <h5>
                &nbsp;&nbsp; Referral Bonus Balance:
                <b>N{{ referralBonusBalance }}</b>
              </h5>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-12 col-lg-12" v-if="bankDetails == 0" align="center">
          <p align="center">
            <a
              :href="'/#/add-bank-details'"
              class="btn btn-danger btn-block"
              style="width: 320px !important; margin-top: 45px"
              ><h3 style="color: #fff !important">Add Bank Details</h3></a
            >
          </p>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <card type="default" header-classes="bg-transparent">
            <div slot="header" class="row align-items-center">
              <div class="col-xl-12">
                <h5 class="h3 text-white mb-0">
                  Request for Coins Reward Withdrawal
                </h5>
                <h4 style="color: yellow">
                  Avaliable amount to withdrawal: N{{ referralBonusBalance }}
                </h4>
              </div>
              <div class="col-xl-12">
                <div class="form-group mb-0">
                  <form @submit.prevent="requestWithdrawal">
                    <base-input
                      placeholder="Enter the Amount in number e.g 1000, 2000, 5000, 100000"
                      class="input-group-alternative"
                      type="number"
                      addon-right-icon="fas fa-search"
                      v-model="withdrawal"
                    >
                    </base-input>
                    <button type="submit" class="btn btn-primary">
                      Request Withdrawal
                    </button>
                  </form>
                  <div>
                    <p style="color: yellow">
                      {{ withdrawalError }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
      </div>
      <!-- Dark table -->
      <div class="row" style="margin-top: 50px">
        <div class="col">
          <div class="card bg-default shadow">
            <div class="card-header bg-transparent border-0">
              <h3 class="text-white mb-0">Withdrawal History</h3>
            </div>

            <div class="table-responsive">
              <table class="table align-items-center table-dark table-flush">
                <!-- Dark table -->
                <thead class="thead-dark">
                  <th>Amount Requested</th>
                  <th>Status</th>
                  <th>Data</th>
                </thead>
                <tbody>
                  <tr v-for="item in withdrawalHistory" v-bind:key="item.id">
                    <td>N {{ item.request_amount }}.00</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.created_date | formatdate }}</td>
                  </tr>
                </tbody>
                <!-- Dark table -->
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Dark table -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "referral-bonus-withdrawal",
  components: {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("userData")).token,
      headers: "",
      totalReferralBonusEarned: "0",
      totalBonusRequested: "0",
      referralBonusBalance: "0",
      withdrawal: "",
      withdrawalHistory: [],
      withdrawalError: "",
      bankDetails: "",
    };
  },
  created() {
    this.headers = { Authorization: `Token ${this.token}` };
    this.detReferralBonusBalance();
    this.getWithdrawalHistory();
    this.getBankdetails();
  },
  methods: {
    detReferralBonusBalance() {
      axios
        .get(API_URL + "referral/referral-balance/", { headers: this.headers })
        .then((res) => {
          this.totalReferralBonusEarned =
            "N " + String(res.data[0].total_referal_bonuses);
          this.totalBonusRequested = res.data[0].total_amount_received;
          this.referralBonusBalance = res.data[0].total_balance;
        });
    },
    requestWithdrawal() {
      const withdrawal_request = parseInt(this.withdrawal, 10);

      var postData = {
        withdrawal_request: withdrawal_request,
      };
      axios
        .post(API_URL + "referral/referral-withdrawal/", postData, {
          headers: this.headers,
        })
        .then((res) => {
          if (res.data.data == "Successful") {
            alert("Your Withdrawal request was Successfull!");
          }
          if (res.data.data == "NotEnoughBonus") {
            alert(
              "You don't have Enough Bonus to withdraw from your Referral Bonus Wallet!"
            );
          }
          if (res.data.data == "SorryTryLater") {
            alert("Settings Error, Try Again Later!");
          }
          if (res.data.data == "YouCantWithdrawLessThanN1000") {
            this.withdrawalError = "You Can't Withdraw Less Than N1000";

            setTimeout(() => {
              this.withdrawalError = "";
            }, 2000);
          }
          this.withdrawal = "";
          this.getWithdrawalHistory();
          this.detReferralBonusBalance();
        });
    },
    getWithdrawalHistory() {
      axios
        .get(API_URL + "referral/referral-withdrawal-history/", {
          headers: this.headers,
        })
        .then((res) => {
          this.withdrawalHistory = res.data;
        });
    },
    getBankdetails() {
      axios
        .get(API_URL + "profile/user-bank-details/", { headers: this.headers })
        .then((res) => {
          this.bankDetails = res.data.length;
        });
    },
  },
};
</script>
<style>
th,
td {
  font-size: 14px !important;
}
</style>
