<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-3"></div>
        <div class="col-xl-6 col-lg-6">
          <stats-card
            title="Congrats!"
            type="gradient-orange"
            icon="ni ni-credit-card"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <h3><b>Your Payment was Successful!</b></h3>
              <!-- <hr v-if="show_confirm_button" /> -->
              <!-- <p v-if="show_confirm_button" align="center">
                <b> Click below to Confirm your Payment</b> <br />
                <a
                  :href="webToChatUrl"
                  class="btn btn-primary btn-block"
                  style="color: #fff"
                  target="_blank"
                  ><h2 style="color: #fff">Confirm Payment</h2>
                </a>
                <span style="color: red" width="80%"
                  ><b
                    >Please note that it may take up to 24hours to process and
                    confirmed your payment</b
                  ></span
                >
              </p> -->
              <hr v-if="show_confirm_button" />
              <p>Please wait 15 seconds while we process your payment...</p>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-3"></div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import store from "../store";
import router from "../router";
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "payment-success",
  components: {},
  data() {
    return {
      transaction_public_id: "",
      reference: "",
      status: "",
      charged_amount: "",
      currency: "",
      vote_contestant_id: "",
      show_confirm_button: false,
      webToChatUrl: "",
    };
  },
  created() {
    this.transaction_public_id = this.$route.query.public_id;
    this.reference = this.$route.query.reference;
    this.status = this.$route.query.status;
    this.charged_amount = this.$route.query.charged_amount;
    this.currency = this.$route.query.currency;
    this.vote_contestant_id = this.$route.query.vote_contestant_id;
    //console.log(this.vote_contestant_id, this.transaction_public_id, this.reference, this.status, this.charged_amount,this.currency)
    this.checkPaymentReference(
      this.transaction_public_id,
      this.reference,
      this.status,
      this.charged_amount,
      this.currency,
      this.vote_contestant_id
    );
    this.postPaymentComplain();
  },
  methods: {
    checkPaymentReference(
      transaction_public_id,
      reference,
      status,
      charged_amount,
      currency,
      vote_contestant_id
    ) {
      //console.log('getRefer', transaction_public_id, reference, status, charged_amount, currency, vote_contestant_id)

      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      var postData = {
        transaction_public_id: transaction_public_id,
        reference: reference,
        status: status,
        charged_amount: charged_amount,
        currency: currency,
      };
      axios
        .post(API_URL + "wallet/confirm-payment/", postData, {
          headers: headers,
        })
        .then((res) => {
          //console.log('vote_contestant_id suucess 1', vote_contestant_id)
          this.confirmPaymentManually();
          setTimeout(function () {
            //console.log('vote_contestant_id suucess 2', vote_contestant_id)
            if (vote_contestant_id != "null") {
              router.push("/vote-contestant/" + vote_contestant_id);
              //alert('Payment was Successful !')
            } else {
              router.push("/dashboard");
              //alert('Payment was Successful !')
            }
          }, 15000);
        })
        .catch((err) => {
          //console.log('vote_contestant_id error 1', vote_contestant_id)
          this.confirmPaymentManually();
          setTimeout(function () {
            //console.log('vote_contestant_id error 2', vote_contestant_id)
            if (vote_contestant_id != "null") {
              router.push("/vote-contestant/" + vote_contestant_id);
              //alert('Payment URL is have Expired or Invalid !')
            } else {
              router.push("/dashboard");
              //alert('Payment URL is have Expired or Invalid !')
            }
          }, 15000);
        });
    },
    confirmPaymentManually() {
      setTimeout(() => {
        this.show_confirm_button = true;
      }, 3000);
    },
    postPaymentComplain() {
      //console.log('payment_ref 2==', this.payment_ref)
      this.webToChatUrl =
        "https://wa.me/2348157043034?text=Hello! Kindly Comfirm this payment - Payment Reference No: " +
        this.reference;
    },
  },
};
</script>
<style></style>
