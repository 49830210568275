<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <div class="container-fluid mt--7" style="margin-top: 0px !important">
        <!-- Dark table -->
        <div class="row">
          <div class="col">
            <div
              class="card bg-default shadow"
              style="padding: 20px !important"
            >
              <div class="row">
                <div
                  class="col-xl-4 col-lg-6"
                  v-for="item in realityTVshows"
                  v-bind:key="item.id"
                >
                  <div class="card card-profile" v-if="item.video">
                    <video
                      :src="item.video"
                      alt="Image placeholder"
                      controls
                      type="video/mp4"
                      :poster="item.video_thumb"
                      class="card-img-top"
                    ></video>
                  </div>
                  <div class="card card-profile" v-else-if="item.cover_banner">
                    <img
                      :src="item.cover_banner"
                      alt="Image placeholder"
                      class="card-img-top"
                    />
                  </div>
                  <stats-card :sub-title="item.title" class="mb-4 mb-xl-0">
                    <template slot="footer">
                      <span class="text-wrap">{{
                        item.short_description | truncate(75, "...")
                      }}</span>
                      <div id="aDivColor">
                        <a
                          :href="'/#/live-show-vote/' + item.contest_id + '/'"
                          id="aBtnColor"
                          class="btn btn-primary btn-block"
                          >View the show</a
                        >
                      </div>
                      <div id="aDivColor">
                        <a
                          :href="
                            '/#/about-reality-tv-shows/' + item.contest_id + '/'
                          "
                          id="aBtnColor"
                          class="btn btn-success btn-block"
                          >About the show</a
                        >
                      </div>
                    </template>
                  </stats-card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Dark table -->
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";

export default {
  name: "reality-tv-shows",
  components: {},
  data() {
    return {
      realityTVshows: "",
    };
  },
  created() {
    this.getRealityTVshows();
  },
  methods: {
    getRealityTVshows() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "contest/reality-tv-shows/", { headers: headers })
        .then((res) => {
          this.realityTVshows = res.data;
          //console.log('res ==', res.data)
        })
        .catch((err) => {
          //console.log('Upcoming err ==', err)
        });
    },
  },
};
</script>
<style>
#aBtnColor {
  color: white !important;
}
#aDivColor {
  padding: 5px !important;
}
</style>
