<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign up</small>
          </div>
          <div class="" align="center">
            <h4>Signup to Vote for your Contestant</h4>
            <h3>Contestant ID: {{ contestant_id }}</h3>
          </div>
        </div>
        <div
          class="card-body px-lg-5 py-lg-5"
          style="margin-top: -50px !important"
        >
          <form role="form" @submit.prevent="onSignup">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Userame"
              addon-left-icon="ni ni-single-02"
              v-model="model.username"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Repeat Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password2"
            >
            </base-input>

            <!-- <base-input class="input-group-alternative mb-3"
                                    placeholder="Email"
                                    addon-left-icon="ni ni-email-83"
                                    v-model="model.email">
                        </base-input> -->

            <div class="form-group">
              <div
                class="
                  input-group input-group-merge input-group-alternative
                  mb-3
                "
              >
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="ni ni-email-83"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  v-model="model.email"
                  placeholder="Email"
                  type="email"
                  required
                />
              </div>
            </div>

            <base-input
              class="input-group-alternative mb-3"
              placeholder="First Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.first_name"
            >
            </base-input>

            <base-input
              class="input-group-alternative mb-3"
              placeholder="Last Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.last_name"
            >
            </base-input>

            <div class="form-group">
              <div class="input-group input-group-merge">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><i class="fas fa-globe-americas"></i
                  ></span>
                </div>
                <input
                  class="form-control"
                  placeholder="234"
                  v-model="model.phone_number"
                  required
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="fas fa-phone"></i
                  ></span>
                </div>
              </div>
            </div>

            <!-- <div class="text-muted font-italic">
                            <small>password strength: <span class="text-success font-weight-700">strong</span></small>
                        </div> -->
            <div class="text-center font-italic">
              <span class="text-danger" v-text="comparePasswords"></span>
            </div>

            <div class="row my-4">
              <div class="col-12">
                <base-checkbox class="custom-control-alternative">
                  <span class="text-muted"
                    >I agree with the <a href="#!">Privacy Policy</a></span
                  >
                </base-checkbox>
              </div>
            </div>
            <div class="text-center">
              <button type="submit" class="btn btn-primary">
                Create account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PASSWORD_API_URL from "../common/forget-password-url";

export default {
  name: "AuditionLogin",
  data() {
    return {
      model: {
        username: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password2: "",
        phone_number: "",
        contestant_id: "",
      },
      href: PASSWORD_API_URL + "/password-reset/",
    };
  },
  created() {
    this.contestant_id = this.$route.params.id;
    ////console.log('this.contestant_id', this.contestant_id)
  },
  computed: {
    comparePasswords() {
      return this.model.password !== this.model.password2
        ? "Passwords do not match"
        : "";
    },
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        ////console.log('token 1=')
        this.$router.push("/vote-contestant/" + this.contestant_id);
      }
    },
    token(value) {
      //console.log('token 2=', value)
    },
  },
  methods: {
    onSignup() {
      if (
        this.model.username &&
        this.model.password &&
        this.model.first_name &&
        this.model.last_name &&
        this.model.password2 &&
        this.model.email &&
        this.model.phone_number
      ) {
        ////console.log('username', this.model.username, 'password', this.model.password)
        this.$store.dispatch("signUserUp", {
          username: this.model.username,
          password: this.model.password,
          email: this.model.email,
          first_name: this.model.first_name,
          last_name: this.model.last_name,
          phone_number: this.model.phone_number,
        });
      } else {
        alert("Please Enter all Required Fields!");
      }
    },
  },
};
</script>
<style></style>
