<template>
  <!-- Main content -->
  <!-- <div class="container mt--8 pb-5"> -->
  <div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    />
    <body class="text-left">
      <!-- Pre Loader Strat  -->
      <!-- <div class="loadscreen" id="preloader">
        <div class="loader spinner-bubble spinner-bubble-primary"></div>
      </div> -->
      <div class="app-landing-wrap landing_wrap">
        <div id="landing_wrap" class="landing-indigo-light">
          <!--=============== Header start ================-->
          <div
            class="main-header header-fixed-default"
            id="home-header"
            style="
              background-color: #776dcd;
              padding-top: 10px !important;
              padding-bottom: 0px !important;
              padding-left: 10px !important;
              padding-right: 10px !important;
            "
          >
            <nav
              class="
                navbar
                w-100
                navbar-expand-lg navbar-transparent
                bg-transparent
              "
              style="background-color: #776dcd !important"
            >
              <div class="logo">
                <img
                  src="./../assets/images/logo.png"
                  alt=""
                  style="width: 70% !important; height: 70% !important"
                />
              </div>

              <div style="margin: auto"></div>

              <div class="header-part-right">
                <!-- Navbar -->
                <base-nav
                  class="navbar-top navbar-horizontal navbar-dark"
                  containerClasses="px-4 container"
                  expand
                >
                  <template v-slot="{ closeMenu }">
                    <!-- Collapse header -->
                    <div class="navbar-collapse-header d-md-none">
                      <div class="row">
                        <div class="col-6 collapse-close">
                          <button
                            type="button"
                            @click="closeMenu"
                            class="navbar-toggler"
                            aria-label="Toggle sidenav"
                          >
                            <span></span>
                            <span></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Navbar items -->
                    <ul class="navbar-nav ml-auto">
                      <li class="nav-item">
                        <router-link class="nav-link nav-link-icon" to="/">
                          <i class="ni ni-planet"></i>
                          <!-- <span class="nav-link-inner--text">Home</span> -->
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="!LoginNavGuard">
                        <router-link
                          class="nav-link nav-link-icon"
                          to="/register"
                        >
                          <i class="ni ni-circle-08"></i>
                          <span class="nav-link-inner--text">Register</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="!LoginNavGuard">
                        <router-link class="nav-link nav-link-icon" to="/login">
                          <i class="ni ni-key-25"></i>
                          <span class="nav-link-inner--text">Login</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="LoginNavGuard">
                        <router-link
                          class="nav-link nav-link-icon"
                          to="/dashboard"
                        >
                          <i class="ni ni-key-25"></i>
                          <span class="nav-link-inner--text">Dashboard</span>
                        </router-link>
                      </li>
                      <li class="nav-item" v-if="LoginNavGuard">
                        <a class="nav-link nav-link-icon">
                          <i class="ni ni-user-run"></i>
                          <span @click="onLogout" class="nav-link-inner--text"
                            >Logout</span
                          >
                        </a>
                      </li>
                    </ul>
                  </template>
                </base-nav>
              </div>
            </nav>
          </div>

          <!--=============== Header End ================-->

          <div class="main-content-wrap">
            <section
              id="intro-wrap"
              class="intro-nine-Wrap bg-primary text-left text-white"
            >
              <div class="container">
                <div class="row">
                  <div class="col-md-6 intro-nine-LeftSection pb-3">
                    <h1
                      v-if="IsHeaderIntro"
                      class="font-weight-bold text-white text-42 mb-3 t-shadow"
                    >
                      {{ headerIntro[0].title }}
                    </h1>
                    <p
                      v-if="IsHeaderIntro"
                      class="text-20 mb-4"
                      v-html="headerIntro[0].description"
                    ></p>
                    <div class="mb-5 lead">
                      <ul
                        class="list-inline"
                        v-for="item in headerList"
                        :key="item.id"
                      >
                        <li :data-aos="item.animation" class="mb-3">
                          <i
                            class="eva eva-checkmark-outline mr-2 text-warning"
                          ></i
                          >{{ item.list }}
                        </li>
                      </ul>
                    </div>
                    <div class="intro-nine-Buttons" v-if="!LoginNavGuard">
                        <a name="" id="" href="/#/login" class="btn half-button btn-outline-white btn-lg pl-5 pr-5 pb-2 mr-2 mb-4 text-uppercase"  role="button">

                            Sign in
                        </a>
                        <a id="" href="/#/register" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase"  role="button">

                            Sign up
                        </a>
                    </div>
                    <div class="intro-nine-Buttons" v-if="LoginNavGuard">
                        <a name="" id="" href="/#/dashboard" class="btn half-button btn-outline-white btn-lg pl-5 pr-5 pb-2 mr-2 mb-4 text-uppercase"  role="button">
                            Dashboard
                        </a>
                        <a id="" class="btn half-button btn-warning btn-lg pl-5 pr-5 pb-2 mb-4 text-uppercase"  role="button" >
                            <span @click="onLogout">Logout</span>
                        </a>
                    </div>
                  </div>
                  <div
                    v-if="IsHeaderIntro"
                    class="
                      col-md-5
                      offset-md-1
                      intro-nine-RightSection
                      d-flex
                      align-items-center
                    "
                  >
                    <div
                      class="intro-nine-ProductImage"
                      v-if="headerIntro[0].video"
                    >
                      <video
                        :src="headerIntro[0].video"
                        :poster="headerIntro[0].video_thumb"
                        controls
                        type="video/media"
                        width="380px"
                        class="img-responsive"
                        alt="img-intro"
                      ></video>
                    </div>
                    <div
                      class="intro-nine-ProductImage"
                      v-else-if="headerIntro[0].header_banner"
                    >
                      <img
                        :src="headerIntro[0].header_banner"
                        width="380px"
                        class="img-responsive"
                        alt="img-intro"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="overlay"></div>
            </section>

            <!-- <section class="left-image-wrap p-t-b-80">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12 mb-5 mb-xl-0">
                    <card type="default" header-classes="bg-transparent">
                      <div slot="header" class="row align-items-center">
                        <div class="col-xl-12">
                          <h5 class="h3 text-white mb-0">
                            Search and Vote for your Candidate(s) - Audition
                          </h5>
                        </div>
                        <div class="col-xl-12">
                          <div class="form-group mb-0">
                            <form @submit.prevent="submitSearch">
                              <base-input
                                placeholder="Enter Contestant's ID"
                                class="input-group-alternative"
                                type="number"
                                addon-right-icon="fas fa-search"
                                v-model="search"
                              >
                              </base-input>
                              <button
                                type="submit"
                                class="btn btn-primary btn-block"
                              >
                                <h3 style="color: #fff">Search</h3>
                              </button>
                            </form>
                          </div>
                        </div>
                      </div>
                    </card>
                  </div>
                </div>
                End Search

                Search Results
                <div class="row mt-5">
                  <div class="col-xl-6">
                    <div
                      class="card"
                      v-for="item in Contestant"
                      v-bind:key="item.id"
                    >
                      <div class="card-header">
                        <h5 class="h3 mb-0">Search Result (s)</h5>
                      </div>
                      <div class="card-header d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <a v-if="item.photo">
                            <img :src="item.photo" class="avatar" />
                          </a>
                          <div class="mx-3">
                            <a class="text-dark font-weight-600 text-sm"
                              >{{ item.first_name }} {{ item.last_name }}</a
                            >
                            <small class="d-block text-muted"
                              >Contestant ID: {{ item.contestant_id }}</small
                            >
                          </div>
                        </div>
                        <div
                          class="text-right ml-auto"
                          style="background-color: red !important"
                        >
                          <b-button
                            v-b-modal.modal-prevent-closing
                            class="btn btn-sm btn-danger btn-icon"
                            style="background-color: red !important"
                          >
                            <h4>
                              <span
                                class="btn-inner--icon"
                                style="color: #fff !important"
                                ><i class="ni ni-fat-add"></i
                              ></span>
                              <span
                                class="btn-inner--text"
                                style="color: #fff !important"
                              >
                                <b>Vote</b>
                              </span>
                            </h4>
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="item.photo">
                          <img
                            :src="item.photo"
                            alt="Image placeholder"
                            class="img-fluid rounded"
                          />
                        </div>
                        <br /><br />
                        <p class="" style="padding: 0px !important">
                          <span
                            style="marign-bottom: 0px !important"
                            v-html="item.about_contestant"
                          ></span>
                        </p>
                        <div
                          class="row align-items-center my-3 pb-3 border-bottom"
                        >
                          <div class="col-sm-6">
                            <div class="icon-actions">
                              <a href="#" class="like active">
                                <i class="ni ni-like-2"></i>
                                <span class="text-muted">
                                  {{ item.vote_count }}</span
                                >
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                End Search Results

                All Contestants
                <div class="row">
                  <div class="col-xl-12"><h2>Top Voted Contestants</h2></div>
                  <div class="col-md-4 col-xl-4" v-for="item in allContestants" v-bind:key="item.id">
                    <div class="card">
                      <div
                        style="
                          background-color: #172b4c !important;
                          color: #fff !important;
                        "
                        class="card-header dark"
                      >
                        <h5 class="h3 mb-0" style="color: #fff !important">
                          Contest: {{ item.contest_title }}
                        </h5>
                      </div>
                      <div class="card-header d-flex align-items-center">
                        <div class="d-flex align-items-center">
                          <a v-if="item.photo">
                            <img :src="item.photo" class="avatar" />
                          </a>
                          <div class="mx-3">
                            <a class="text-dark font-weight-600 text-sm"
                              >{{ item.first_name }} {{ item.last_name }}</a
                            >
                            <small class="d-block text-muted"
                              >Contestant ID: {{ item.contestant_id }}</small
                            >
                          </div>
                        </div>
                        <div
                          class="text-right ml-auto"
                          style="background-color: red !important"
                        >
                          <b-button
                            v-b-modal.modal-prevent-closing2
                            class="btn btn-sm btn-danger btn-icon"
                            v-on:click="startContestantVote(item.contestant_id)"
                            style="background-color: red !important"
                          >
                            <h5>
                              <span
                                class="btn-inner--icon"
                                style="color: #fff !important"
                                ><i class="ni ni-fat-add"></i
                              ></span>
                              <span
                                class="btn-inner--text"
                                style="color: #fff !important"
                                ><b>Vote</b></span
                              >
                            </h5>
                          </b-button>
                        </div>
                      </div>
                      <div class="card-body">
                        <div v-if="item.photo">
                          <img
                            :src="item.photo"
                            alt="Image placeholder"
                            class="img-fluid rounded"
                          />
                        </div>
                        <br /><br />
                        <div
                          class=""
                          style="
                            padding: 0px !important;
                            font-size: 10px !important;
                          "
                        >
                          <span
                            style="
                              marign-bottom: 0px !important;
                              font-size: 10px !important;
                            "
                            v-html="item.about_contestant"
                          ></span>
                        </div>
                        <div
                          class="row align-items-center my-3 pb-3 border-bottom"
                        >
                          <div class="col-sm-6">
                            <div class="icon-actions">
                              <a href="#" class="like active">
                                <i class="ni ni-like-2"></i>
                                <span class="text-muted">
                                  {{ item.vote_count }}</span
                                >
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </section> -->
            <!--End All Contestants-->

            <!-- Start Voting Model -->
            <!-- hide-backdrop -->
            <!-- <div v-for="item in Contestant" v-bind:key="item.id">
              
              <b-modal
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                id="modal-prevent-closing"
                ref="modal"
                title="Vote Now!"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk"
              >
                <div class="d-block">
                  <p>
                    <b>Vote for Contestant:</b> {{ item.first_name }}
                    {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id
                    }}<br />Vote Cost: N{{ voting_cost }}/vote
                  </p>
                  <br />Select Number of Vote:
                </div>

                <form ref="form" @submit.stop.prevent="handleSubmit">
                  <b-form-group
                    :state="nameState"
                    :label="minimum_voting"
                    label-for="name-input"
                    invalid-feedback="Vote is required"
                  >
                    <b-form-select
                      id="name-input"
                      v-model="voteCount"
                      :options="variants"
                      :state="nameState"
                      required
                    ></b-form-select>
                  </b-form-group>
                </form>
                <div class="d-block" style="margin-top: 10px">
                  <h5 align="center"><b>Voting Amount:</b></h5>
                  <h3 align="center">N{{ amountToPay }}.00</h3>
                  <p align="center" style="color: red">
                    Kindly Send the this Amount (N{{ amountToPay }}.00) to the
                    Account Below:
                  </p>
                  
                  <p style="margin-left: 25%">
                    Acct Name: <b>DH ONLINE CONTEST</b><br />
                    Acct No : <b>0511894421</b><br />
                    Bank : <b>GTBank</b>
                  </p>
                  <p align="center">
                    Click the below Button to Generate Payment ID and Send
                    Payment Confirmation after Successful Payment.<br />
                    <span style="color: red"
                      >Make sure you use and send the same Amount of Money with
                      this Payment ID.</span
                    >
                  </p>
                  <div align="center">
                    <button
                      v-if="!payment_id_generated_completed"
                      class="btn btn-success"
                      @click="generateManualPayment"
                    >
                      <h5 style="color: #fff">Generate Payment ID</h5>
                    </button>
                    <button
                      v-if="payment_id_generated_completed"
                      class="btn btn-warning"
                    >
                      <b>Payment ID Generated Completed...</b>
                    </button>
                  </div>
                  
                  <form style="margin-top: 30px" v-if="is_payment_generated">
                    <h6 align="center">
                      Payment ID: <b>{{ payment_id }}</b>
                    </h6>
                    <div class="form-group" align="center">
                      <input
                        class="form-control"
                        readonly="readonly"
                        :value="payment_id"
                        id="payment-id"
                        align="center"
                      />
                    </div>
                    <a
                      :href="webToChatUrl"
                      class="btn btn-primary btn-block"
                      style="color: #fff"
                      target="_blank"
                      ><b
                        >1). Click here to Send Confirmation via Whatsapp, <br> if
                        you're on Smart Phone</b
                      ></a
                    >
                    <button
                      type="submit"
                      @click="Copy()"
                      class="btn btn-success btn-block"
                    >
                      <b
                        >2). or Click here to Copy & Send Confirmation <br> via SMS
                        to this No. 09096577347</b
                      >
                    </button>
                  </form>
                </div>
              </b-modal>
            </div> -->

            <!-- start Modal 2 -->
            <!-- hide-backdrop -->
            <!-- <div v-for="item in allContestants" v-bind:key="item.id">
              
              <b-modal
                v-if="item.contestant_id == constestant_Id"
                :header-bg-variant="headerBgVariant"
                :header-text-variant="headerTextVariant"
                id="modal-prevent-closing2"
                ref="modal"
                title="Vote Now!"
                @show="resetModal"
                @hidden="resetModal"
                @ok="handleOk2"
              >
                <div class="d-block">
                  <p>
                    <b>Vote for Contestant:</b> {{ item.first_name }}
                    {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id
                    }}<br />Vote Cost: N{{ voting_cost }}/vote
                  </p>
                  <br />Select Number of Vote:
                </div>

                <form ref="form" @submit.stop.prevent="handleSubmit2">
                  <b-form-group
                    :state="nameState"
                    :label="minimum_voting"
                    label-for="name-input"
                    invalid-feedback="Vote is required"
                  >
                    <b-form-select
                      id="name-input"
                      v-model="voteCount"
                      :options="variants"
                      :state="nameState"
                      required
                    ></b-form-select>
                  </b-form-group>
                </form>
                <div class="d-block" style="margin-top: 10px">
                  <h5 align="center"><b>Voting Amount:</b></h5>
                  <h3 align="center">N{{ amountToPay }}.00</h3>
                  <p align="center" style="color: red">
                    Kindly Send the this Amount (N{{ amountToPay }}.00) to the
                    Account Below:
                  </p>
           
                  <p style="margin-left: 25%">
                    Acct Name: <b>DH ONLINE CONTEST</b><br />
                    Acct No : <b>0511894421</b><br />
                    Bank : <b>GTBank</b>
                  </p>
                  <p align="center">
                    Click the below Button to Generate Payment ID and Send
                    Payment Confirmation after Successful Payment.<br />
                    <span style="color: red"
                      >Make sure you use and send the same Amount of Money with
                      this Payment ID.</span
                    >
                  </p>
                  <div align="center">
                    <button
                      v-if="!payment_id_generated_completed"
                      class="btn btn-success"
                      @click="generateManualPayment"
                    >
                      <h5 style="color: #fff">Generate Payment ID</h5>
                    </button>
                    <button
                      v-if="payment_id_generated_completed"
                      class="btn btn-warning"
                    >
                      <b>Payment ID Generated Completed...</b>
                    </button>
                  </div>
                
                  <form style="margin-top: 30px" v-if="is_payment_generated">
                    <h6 align="center">
                      Payment ID: <b>{{ payment_id }}</b>
                    </h6>
                    <div class="form-group" align="center">
                      <input
                        class="form-control"
                        readonly="readonly"
                        :value="payment_id"
                        id="payment-id"
                        align="center"
                      />
                    </div>
                    <a
                      :href="webToChatUrl"
                      class="btn btn-primary btn-block"
                      style="color: #fff"
                      target="_blank"
                      ><b
                        >1). Click here to Send Confirmation via Whatsapp, <br> if
                        you're on Smart Phone</b
                      ></a
                    >
                    <button
                      type="submit"
                      @click="Copy()"
                      class="btn btn-success btn-block"
                    >
                      <b
                        >2). or Click here to Copy & Send Confirmation <br> via SMS
                        to this No. 09096577347</b
                      >
                    </button>
                  </form>
                </div>
              </b-modal>
            </div> -->
            <!-- end Modal 2 -->

            <!-- start::About DH Contest -->
            <section class="left-image-wrap p-t-b-80">
              <div class="container">
                <div class="row">
                  <div
                    v-if="IsAboutDhIntro"
                    class="col-md-12 section-header mb-5"
                  >
                    <h2 class="font-weight-bold">
                      {{ aboutDhIntro[0].title }}
                    </h2>
                    <p v-html="aboutDhIntro[0].description"></p>
                  </div>
                  <div
                    style="margin-top: -20px !important"
                    class="col-md-6 col-sm-12 mb-5 mb-lg-0 mb-md-0 mt-mb-5"
                  >
                    <div class="left-image-carousel">
                      <div v-if="IsAboutDhIntro">
                        <video
                          v-if="aboutDhIntro[0].video"
                          :src="aboutDhIntro[0].video"
                          :poster="aboutDhIntro[0].video_thumb"
                          controls
                          type="video/media"
                          width="100%"
                          class="img-responsive"
                          alt="img-intro"
                        ></video>

                        <img
                          v-else-if="aboutDhIntro[0].banner"
                          alt="Card image cap"
                          class="img-responsive w-100 mb-4"
                          :src="aboutDhIntro[0].banner"
                        />
                      </div>
                    </div>
                    <!-- <div class="slick_control_wrap text-center">
                        <a type="button" class="ngu_control_button half-button leftRs btn btn-circle btn-white btn-shadow" style="width: 180px !important;"><i class="eva eva-chevron-right-outline">Login</i></a>
                    </div> -->
                  </div>
                  <div class="col-md-5 offset-md-1 col-sm-12">
                    <div class="row" v-for="item in aboutDhList" :key="item.id">
                      <div
                        :data-aos="item.animation"
                        class="col-md-12 col-sm-6 mb-4"
                      >
                        <div class="card">
                          <div class="card-body pt-3 pb-3">
                            <h4 class="card-title font-weight-bold">
                              {{ item.list_title }}
                            </h4>
                            <p class="m-0" v-html="item.details"></p>
                          </div>
                        </div>
                      </div>

                      <!-- end of col  -->
                    </div>
                  </div>
                  <!-- end of col6 row -->
                </div>
              </div>
            </section>
            <!-- end::About DH Contest -->

            <!-- Reality Show Home Report -->
            <section id="intro-wrap" class="bg-primary text-left text-white">
              <div class="container" style="padding: 50px 0">
                <h2 class="font-weight-bold text-white" data-aos="fade-left">
                  View TV Reality Show Results
                </h2>
                <p class="font-weight-bold text-white" data-aos="fade-right">
                  All Conestants need to Check their Voting and Status Details
                  with their Contestant / Voting ID.
                </p>
                <hr style="color: #fff !important" />
                <div class="row" align="center">
                  <div class="col-lg-4" data-aos="fade-up">
                    <h3 class="text-white">Audtion</h3>
                    <a :href="'/#/contestant-profile'" class="btn btn-success"
                      ><b>Learn more...</b></a
                    >
                  </div>
                  <div class="col-lg-4" data-aos="fade-right">
                    <h3 class="text-white">Camp</h3>
                    <a href="#" class="btn btn-success"><b>Learn more...</b></a>
                  </div>
                  <div class="col-lg-4" data-aos="fade-down">
                    <h3 class="text-white">Best Task</h3>
                    <a href="#" class="btn btn-success"><b>Learn more...</b></a>
                  </div>
                </div>
              </div>
            </section>
            <!-- Reality Show Home Report -->

            <!-- start:: Event Poster -->
            <section id="pricing-wrap" class="pricing-two p-t-b-80 light-gray">
              <div class="container">
                <div class="row">
                  <div class="col-md-12" v-if="IsEventPoster">
                    <div
                      class="section-header col-md-12 mb-5"
                      v-for="item in eventPosterIntro"
                      :key="item.id"
                    >
                      <h2 class="font-weight-bold">{{ item.title }}</h2>
                      <p v-html="item.description"></p>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div
                    class="col-md-6 col-lg-4 mb-4 mb-lg-0 mt-3"
                    v-for="item in eventPosterList"
                    :key="item.id"
                    :data-aos="item.animation"
                    :data-aos-duration="item.duration"
                    :data-aos-delay="item.delay"
                  >
                    <div class="card service-three--hover">
                      <div class="card-body">
                        <img v-if="item.poster" :src="item.poster" />
                        <p
                          v-if="item.description"
                          class="text-10 font-weight-500 text-muted mt-3"
                          v-html="item.description"
                        ></p>
                        <a
                          :href="item.button_url"
                          class="btn btn-outline-info mt-4 mb-4"
                          >{{ item.button_text }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <!-- end:: column -->
                </div>
              </div>
            </section>
            <!-- end:: Event Poster -->

            <!-- start::Our judges -->
            <section id="teams-wrap" class="team-wrap p-t-b-80">
              <div class="container">
                <div class="row" v-if="IsJudge">
                  <div
                    class="col-md-12 section-header mb-5"
                    v-for="item in judgeIntro"
                    :key="item.id"
                  >
                    <h2 class="font-weight-bold">{{ item.title }}</h2>
                    <p v-html="item.description"></p>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-for="item in judgeList"
                    :key="item.id"
                    :data-aos="item.animation"
                    class="col-md-6 col-lg-4 col-sm-6 mb-lg-0 mb-4"
                  >
                    <div class="card card-profile-1">
                      <div class="card-body text-center">
                        <div
                          class="avatar box-shadow-2 mb-3"
                          style="
                            width: 120px !important;
                            height: 150px !important;
                          "
                        >
                          <img :src="item.photo" alt="" />
                        </div>
                        <h5 class="font-weight-medium card-title m-0"></h5>
                        <button class="btn half-button btn-gradient">
                          {{ item.full_name }}
                        </button>
                        <p class="mt-0">{{ item.career }}</p>
                        <p
                          v-if="item.description"
                          v-html="item.description"
                        ></p>

                        <div class="card-socials-simple mt-4">
                          <a :href="item.twitter" class="text-20 mr-2">
                            <i class="fa fa-twitter"></i>
                          </a>
                          <a :href="item.instagram" class="text-20 mr-2">
                            <i class="fa fa-instagram"></i>
                          </a>
                          <a :href="item.facebook" class="text-20 mr-2">
                            <i class="fa fa-facebook"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end of col -->
                </div>
              </div>
            </section>
            <!-- end::Our judges -->

            <!-- Testimonies -->
            <section
              id="works-wrap"
              class="work1Wrap light-gray"
              v-if="IsTestimony"
            >
              <div class="container">
                <div class="row">
                  <div class="section-header col-md-12 mb-5" v-if="IsTestimony">
                    <h2 class="font-weight-bold">
                      {{ testimonyIntro[0].title }}
                    </h2>
                    <p v-html="testimonyIntro[0].description"></p>
                  </div>
                  <div
                    v-for="item in testimonyList"
                    :key="item.id"
                    :data-aos="item.animation"
                    class="col-md-6 col-lg-4 col-sm-6 mb-4"
                  >
                    <div class="card o-hidden">
                      <video
                        v-if="item.video"
                        :src="item.video"
                        :poster="item.video_thumb"
                        controls
                        type="video/media"
                        width="100%"
                        class="img-responsive"
                        alt="img-intro"
                      ></video>

                      <img
                        v-else-if="item.photo"
                        class="card-img-top"
                        :src="item.photo"
                        alt="Card image cap"
                      />

                      <div class="card-body">
                        <h5 class="card-title font-weight-bold">
                          {{ item.full_name }}
                        </h5>
                        <p
                          class="card-text"
                          v-if="item.description"
                          {{
                          item.description
                          }}
                        ></p>
                      </div>
                      <div class="card-footer pl-3">
                        <a href="#" class="btn btn-lg p-1 text-dark mr-1">
                          <span class="eva eva-link-2-outline"></span>
                        </a>
                        <a href="#" class="btn btn-lg p-1 text-dark mr-1">
                          <span class="eva eva-share"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <!-- end column -->
                </div>
              </div>
            </section>
            <!-- end::Testinonies -->

            <!-- start::Contact us DH Contest -->
            <section class="left-image-wrap p-t-b-80">
              <div class="container">
                <div class="row">
                  <div
                    v-if="contacttUsDetails"
                    class="col-md-12 mb-4"
                    style="margin-top: -30px !important"
                  >
                    <h2 class="font-weight-bold">Contact Us</h2>
                    <hr />
                    <div>
                      <h5><b>Contact Address:</b></h5>
                      <div v-html="contacttUsDetails[0].contact_address">
                        Contact us Deatils
                      </div>
                      <br />
                      <h5><b>Phone Numbers:</b></h5>
                      <p>{{ contacttUsDetails[0].phone_numbers }}</p>
                      <br />
                      <h5><b>Whatsapp Numbers:</b></h5>
                      <p>{{ contacttUsDetails[0].whatsapp_numbers }}</p>
                      <br />
                      <h5><b>Email Addresses:</b></h5>
                      <p>{{ contacttUsDetails[0].email_address }}</p>
                    </div>
                  </div>
                  <!-- end of col6 row -->
                </div>
              </div>
            </section>
            <!-- end::Contact us DH Contest -->

            <!-- <section> -->
            <footer class="footer px-4" style="margin-top: -100px !important">
              <div class="row align-items-center justify-content-lg-between">
                <div class="col-lg-6">
                  <div class="copyright text-center text-lg-left text-muted">
                    © {{ year }}
                    <a
                      href="https://dhcontest.com"
                      class="font-weight-bold ml-1"
                      >DH Contest</a
                    >
                  </div>
                </div>
                <div class="col-lg-6">
                  <ul
                    class="
                      nav nav-footer
                      justify-content-center justify-content-lg-end
                    "
                  >
                    <li class="nav-item">
                      <router-link to="/" class="nav-link">
                        <span>Home</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/about-us" class="nav-link">
                        <span>About Us</span>
                      </router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/contact-us" class="nav-link">
                        <span>Contact Us</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
            <!-- </section> -->
          </div>
        </div>
      </div>

      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/jquery-3.3.1.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/bootstrap.bundle.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/perfect-scrollbar.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/slick.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/scroll.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/slick.script.min.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/vendor/aos.js"
      ></script>
      <script
        type="application/javascript"
        defer
        src="./../assets/js/es5/script.min.js"
      ></script>
    </body>
  </div>
</template>
<script>
/* eslint-disable */
import "../assets/styles/vendor/slick.css";
import "../assets/styles/vendor/slick-theme.css";
import "../assets/styles/vendor/aos.css";
import "../assets/styles/vendor/perfect-scrollbar.css";

import "./../assets/js/vendor/jquery-3.3.1.min.js";
import "./../assets/js/vendor/aos.js";
import "./../assets/js/es5/script.min.js";

import axios from "axios";
import API_URL from "./../common/config";
//import ContactUs from './../views/ContactUs';

export default {
  name: "home",
  data() {
    return {
      year: new Date().getFullYear(),
      LoginNavGuard: false,
      IsHeaderIntro: false,
      headerIntro: [],
      headerList: [],
      IsAboutDhIntro: false,
      aboutDhIntro: [],
      aboutDhList: [],
      IsEventPoster: false,
      eventPosterIntro: [],
      eventPosterList: [],
      IsJudge: false,
      judgeIntro: [],
      judgeList: [],
      IsTestimony: false,
      testimonyIntro: [],
      testimonyList: [],
      contacttUsDetails: null,
      allContestants: [],
      search: "",
      Contestant: [],
      constestantId: null,
      constestant_Id: null,
      isEnableVote: "",
      minimum_voting: "",
      minimum_vot_qty: "",
      voting_cost: "",
      name: "",
      nameState: null,
      submittedNames: [],
      voteCount: "",
      variants: [1, 2, 4, 5, 10, 20, 30, 40, 50, 60, 80, 100],
      headerBgVariant: "primary",
      headerTextVariant: "dark",
      totalVoteAmount: "",
      manualPaymentAmount: 0,
      payment_id: "",
      webToChatUrl: "",
      is_payment_generated: false,
      payment_id_generated_completed: false,
      home_audition_id: undefined,
    };
  },
  created() {
    this.home_audition_id = this.$route.query.id;
    this.isLoginMenu();
    this.homeHeader();
    this.aboutDhContest();
    this.eventPoster();
    this.judgeDetail();
    this.testimonyDetail();
    this.contactUs();
    // this.getAllContestantsAuditions()
    // this.autoSearch();
  },
  watch: {
    //console.log('tag', '')
  },
  computed: {
    amountToPay() {
      return this.voting_cost * this.voteCount;
    },
  },
  methods: {
    homeHeader() {
      axios.get(API_URL + "home-page/header-intro/").then((res) => {
        if (res.data.length > 0) {
          this.IsHeaderIntro = true;
          this.headerIntro = res.data;
        } else {
          this.IsHeaderIntro = false;
        }
      });
      axios.get(API_URL + "home-page/header-list/").then((res) => {
        this.headerList = res.data;
      });
    },
    aboutDhContest() {
      axios.get(API_URL + "home-page/about-dh-intro/").then((res) => {
        if (res.data.length > 0) {
          this.IsAboutDhIntro = true;
          this.aboutDhIntro = res.data;
        } else {
          this.IsAboutDhIntro = false;
        }
      });
      axios.get(API_URL + "home-page/about-dh-list/").then((res) => {
        this.aboutDhList = res.data;
      });
    },
    eventPoster() {
      axios.get(API_URL + "home-page/event-poster-intro/").then((res) => {
        if (res.data.length > 0) {
          this.IsEventPoster = true;
          this.eventPosterIntro = res.data;
        } else {
          this.IsEventPoster = false;
        }
      });
      axios.get(API_URL + "home-page/event-poster-list/").then((res) => {
        this.eventPosterList = res.data;
      });
    },
    judgeDetail() {
      axios.get(API_URL + "home-page/judge-intro/").then((res) => {
        if (res.data.length > 0) {
          this.IsJudge = true;
          this.judgeIntro = res.data;
        } else {
          this.IsJudge = false;
        }
      });
      axios.get(API_URL + "home-page/judge-list/").then((res) => {
        this.judgeList = res.data;
      });
    },
    testimonyDetail() {
      axios.get(API_URL + "home-page/testimony-intro/").then((res) => {
        if (res.data.length > 0) {
          this.IsTestimony = true;
          this.testimonyIntro = res.data;
        } else {
          this.IsTestimony = false;
        }
      });
      axios.get(API_URL + "home-page/testimony-list/").then((res) => {
        this.testimonyList = res.data;
      });
    },
    contactUs() {
      axios.get(API_URL + "home-page/contact-us-page/").then((res) => {
        this.contacttUsDetails = res.data;
        //console.log('contacttUsDetails == ', res)
      });
    },
    isLoginMenu() {
      if (localStorage.getItem("userData")) {
        this.LoginNavGuard = true;
        return this.LoginNavGuard;
      } else {
        return this.LoginNavGuard;
      }
    },
    onLogout() {
      //console.log('user logout 1', 'Side bar')
      this.$store.dispatch("logout");
      //console.log('user logout 2', 'Side bar')
    },
    submitSearch() {
      const searchInput = parseInt(this.search, 10);
      ////console.log('searchInput', searchInput)
      axios
        .get(API_URL + "home-page/get-contestant/" + searchInput)
        .then((res) => {
          ////console.log('getContestants', res.data)
          this.Contestant = res.data;
          this.constestantId = res.data[0].contestant_id;
          ////console.log('this.constestantId 1', this.constestantId)
          this.okCheck(res.data[0].contestant_id);
        })
        .catch(() => {
          alert("You have entered Invalid Contestant ID");
        });
    },
    startContestantVote(contestant_id) {
      //var headers = {'Authorization': `Token ${this.token}`}
      const searchInput = parseInt(contestant_id, 10);
      this.constestant_Id = searchInput;
      this.okCheck(this.constestant_Id);
    },
    checkFormValidity() {
      const valid = true; //this.$refs.form.checkValidity()
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.voteCount = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      ////console.log('voteCount', this.voteCount)
      //this.auditionVoting(this.voteCount)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
        window.location.reload();
      });
    },
    okCheck(constestantId) {
      axios
        .get(API_URL + "vote/voting-details-home/" + constestantId)
        .then((res) => {
          ////console.log('okCheck ==', res.data.data)
          this.isEnableVote = res.data.data;
          this.minimum_voting = "Min " + res.data.minimum_voting + " Votes";
          this.minimum_vot_qty = res.data.minimum_voting;
          this.voting_cost = res.data.voting_cost;
        });

      // this.$store.dispatch('loadWalletBalance').then(res => {
      // this.walletBalanceAmount = res
      // })
    },
    handleOk2(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit2();
    },
    handleSubmit2() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //console.log('this.voteCount', this.voteCount)
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      //this.auditionVoting(this.voteCount)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing2");
      });
      window.location.reload();
    },
    /* getAllContestantsAuditions() {
      axios.get(API_URL + "home-page/get-all-contestant/").then((res) => {
        this.allContestants = res.data
      });
    }, */
    generateManualPayment() {
      if (this.constestant_Id == null) {
        var contestantID = this.constestantId;
      }

      if (this.constestantId == null) {
        var contestantID = this.constestant_Id;
      }
      this.manualPaymentAmount = this.voting_cost * this.voteCount;
      if (this.manualPaymentAmount == 0) {
        alert("Please Select the Number of Votes you want to pay for!");
        this.is_payment_generated = false;
      } else {
        axios
          .get(
            API_URL +
              "wallet/generate-payment-home/" +
              contestantID +
              "/" +
              this.voting_cost +
              "/" +
              this.voteCount +
              "/" +
              this.manualPaymentAmount +
              "/"
          )
          .then((res) => {
            console.log("generate-payment-home ==", res.data[0].payment_id);

            this.payment_id = res.data[0].payment_id;
            this.is_payment_generated = true;
            this.postPaymentToWhatsapp();
            this.payment_id_generated_completed = true;
          });
      }
    },
    postPaymentToWhatsapp() {
      if (this.constestant_Id == null) {
        var contestantID = this.constestantId;
      }

      if (this.constestantId == null) {
        var contestantID = this.constestant_Id;
      }

      this.webToChatUrl =
        "https://wa.me/2348157043034?text=Hello! Kindly use this payment id : " +
        this.payment_id +
        " to Vote for Contestant ID: " +
        contestantID;
    },
    Copy() {
      var Url = document.getElementById("payment-id");
      Url.value = this.payment_id;
      Url.focus();
      Url.select();
      document.execCommand("Copy");
      alert(
        "Payment ID " +
          this.payment_id +
          " was Successfully Copied, kindly Send it via SMS No: 09096577347 "
      );
    },
    /* autoSearch() {
      if (this.home_audition_id != undefined) {
        const searchInputs = parseInt(this.home_audition_id, 10);
        axios
          .get(API_URL + "home-page/get-contestant/" + searchInputs)
          .then((res) => {
            this.Contestant = res.data;
            this.constestantId = res.data[0].contestant_id;
            this.okCheck(res.data[0].contestant_id);
          })
          .catch(() => {
            alert("You have entered Invalid Contestant ID");
          });
      }
    }, */
  },
};
</script>
<style scoped src="@/assets/styles/css/themes/lite-purple.min.css"></style>
