<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7">
        <h2 align="center" style="color: #fff; margin-bottom: 5px !important">
          Contestant Profile
        </h2>
      </div>
    </div>
    <div class="row justify-content-center" v-if="Contestant">
      <div class="col-lg-8 col-md-10" style="padding: 25px; color: #fff">
        <flip-countdown v-if="count_down" :deadline="count_down">
        </flip-countdown>
        <!-- 2021-01-25 00:00:00  -->
      </div>
    </div>

    <div
      class="row justify-content-center"
      v-if="Contestant"
      style="margin-bottom: 50px; margin-top: 20px"
    >
      <div class="col-lg-5 col-md-7">
        <div class="card">
          <div class="card-header" align="center">
            <h1 class="justify-content-center">
              <span style="color: red">Vote Target:</span> {{ target_votes }}
            </h1>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div v-html="general_message"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-lg-5 col-md-7" v-if="Contestant">
        <div class="card" v-for="item in Contestant" v-bind:key="item.id">
          <div class="card-header">
            <h5 class="h3 mb-0">{{ contest_title }}</h5>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <a>
                <img v-if="item.photo" :src="item.photo" class="avatar" />
              </a>
              <div class="mx-3">
                <a class="text-dark font-weight-600 text-sm"
                  >{{ item.first_name }} {{ item.last_name }}</a
                >
                <small class="d-block text-muted"
                  >Contestant ID: {{ item.contestant_id }}</small
                >
              </div>
            </div>
            <div class="text-right ml-auto">
              <b-button
                v-b-modal.modal-prevent-closing
                class="btn btn-sm btn-success btn-icon"
              >
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <a :href="contestant_url" style="color: #fff"
                  ><span class="btn-inner--text">Vote</span></a
                >
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <img
              v-if="item.photo"
              alt="Image placeholder"
              :src="item.photo"
              class="img-fluid rounded"
            />
            <br /><br />
            <p class="" style="padding: 0px !important">
              <span
                style="marign-bottom: 0px !important"
                v-html="item.about_contestant"
              ></span>
            </p>
            <div class="row align-items-center my-3 pb-3 border-bottom">
              <div class="col-sm-6">
                <div class="icon-actions">
                  <a href="#" class="like active">
                    <i class="ni ni-like-2"></i>
                    <span class="text-muted"> {{ item.vote_count }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div
      class="row justify-content-center"
      v-if="report"
      style="margin-bottom: 50px; margin-top: 20px"
    >
      <div class="col-lg-5 col-md-7">
        <div class="card">
          <div class="card-header" align="center">
            <h2 class="justify-content-center">
              <span style="color: red">Vote Remarks:</span>
            </h2>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div v-html="report"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--End Search Results-->
    <div class="row justify-content-center" style="margin-top: 30px">
      <div class="col-lg-5 col-md-7">
        <div class="card bg-secondary shadow border-0">
          <div class="card-header bg-transparent pb-5">
            <div class="text-muted text-center mt-2 mb-3">
              <span style="color: #000">Search for your Details</span>
            </div>
          </div>
          <div
            class="card-body px-lg-5 py-lg-5"
            style="margin-top: -50px !important"
          >
            <form role="form" @submit.prevent="submitSearch()">
              <base-input
                class="input-group-alternative mb-3"
                placeholder="Contestant / Vote ID"
                addon-left-icon="ni ni-single-02"
                v-model="model.contestant_id"
              >
              </base-input>

              <div class="text-center">
                <!-- <base-button type="primary" class="my-4"> -->
                <button type="submit" class="btn btn-primary">Search</button>
                <!-- </base-button> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
//import IMG_URL from  './../common/img_url';
import FlipCountdown from "vue2-flip-countdown";
import moment from "moment";
const fmt = "YYYY-MM-DD HH:mm:ss";

export default {
  name: "contestant-profile",
  components: { FlipCountdown },
  data() {
    return {
      model: {
        contestant_id: "",
      },
      Title: "Contestant Profile!",
      Contestant: null,
      contest_title: "",
      contestantId: "",
      contestant_url: "",
      target_votes: "",
      count_down: "",
      general_message: "",
      report: "",
    };
  },
  updated() {
    this.contestant_url = "/#/audition?id=" + this.contestantId;
  },
  computed: {
    deadline1: function () {
      return moment(this.count_down).format(fmt);
    },
  },
  methods: {
    submitSearch() {
      //var headers = {'Authorization': `Token ${this.token}`}
      const contestant_id = parseInt(this.model.contestant_id, 10);
      ////console.log('searchInput', searchInput)
      axios
        .get(API_URL + "audition-result/contestant-profile/" + contestant_id)
        .then((res) => {
          this.Contestant = res.data.data;
          this.contest_title = res.data.data2;
          this.contestantId = res.data.data[0].contestant_id;
          this.getContestantGeneralReport(res.data.data[0].contest_id);
          this.getAuditionStatusReport(res.data.data[0].contest_id);
        })
        .catch(() => {
          alert("You have entered Invalid Contestant ID");
        });
    },
    getContestantGeneralReport(contest_id) {
      axios
        .get(API_URL + "audition-result/general-report/" + contest_id)
        .then((res) => {
          this.general_message = res.data.data[0].general_message;
          this.target_votes = res.data.data[0].target_votes;
          this.count_down = moment(res.data.data[0].count_down).format(fmt);
        });
    },
    getAuditionStatusReport(contest_id) {
      const contestant_id = parseInt(this.model.contestant_id, 10);

      axios
        .get(
          API_URL +
            "audition-result/status-report/" +
            contest_id +
            "/" +
            contestant_id
        )
        .then((res) => {
          console.log("res.data ==", res.data[0].report);
          this.report = res.data[0].report;
        });
    },
  },
};
</script>
<style></style>
