<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div
          class="col-xl-12 col-lg-12"
          v-for="item in aboutTvShow"
          v-bind:key="item.id"
        >
          <stats-card :sub-title="item.title" class="mb-4 mb-xl-0">
            <template slot="footer">
              <div class="card card-profile" v-if="item.video">
                <video
                  :src="item.video"
                  alt="Image placeholder"
                  controls
                  type="video/mp4"
                  :poster="item.video_thumb"
                  class="card-img-top"
                ></video>
              </div>
              <div class="card card-profile" v-else-if="item.cover_banner">
                <img
                  :src="item.cover_banner"
                  alt="Image placeholder"
                  class="card-img-top"
                />
              </div>
              <br />
              <h3>Intro:</h3>
              <p>{{ item.short_description }}</p>
              <br />
              <h2>About the Reality TV Show:</h2>
              <p v-html="item.full_description"></p>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-12 col-lg-12" style="padding: 25px">
          <a
            :href="show_url"
            class="btn btn-success"
            style="background-color: #fff !important; color: red"
            >Watch the Show</a
          >
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "about-reality-tv-shows",
  components: {},
  data() {
    return {
      aboutTvShow: "",
      contest_id: "",
      show_url: "",
    };
  },
  created() {
    this.contest_id = this.$route.params.id;
    this.getAboutTvShow();
    this.show_url = "/#/live-show-vote/" + this.$route.params.id + "/";
  },
  methods: {
    getAboutTvShow() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(
          API_URL + "contest/about-reality-tv-shows/" + this.contest_id + "/",
          { headers: headers }
        )
        .then((res) => {
          this.aboutTvShow = res.data;
          //console.log('Abount res', res)
        });
    },
  },
};
</script>
<style></style>
