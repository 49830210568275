<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div class="col-xl-12 col-lg-12">
          <stats-card>
            <template slot="footer">
              <h3><b>Coming Soon!</b></h3>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "live-stream",
  components: {},
  data() {
    return {
      referralCode: "",
      referralbonus: [],
    };
  },
  created() {
    this.getReferralbonus();
    this.getReferralCode();
  },
  methods: {
    getReferralbonus() {
      this.$store.dispatch("loadReferralBonus").then((data) => {
        this.referralbonus = data;
      });
    },
    getReferralCode() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "referral/referral-code/", { headers: headers })
        .then((res) => {
          this.referralCode = REFERRAL_API_URL + res.data[0].referral_code;
        });
    },
    Copy() {
      var Url = document.getElementById("referral-link");
      Url.value = this.referralCode;
      Url.focus();
      Url.select();
      document.execCommand("Copy");
      alert("Referral Linked Copied");
    },
  },
};
</script>
<style></style>
