<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-lg-6"></div>
      </div>
    </base-header>
    <!--Search Results-->
    <div class="row">
      <div class="col-xl-3"></div>
      <div class="col-xl-6">
        <div class="card" v-for="item in Contestant" v-bind:key="item.id">
          <div class="card-header">
            <h5 class="h3 mb-0">Search Result (s)</h5>
          </div>
          <div class="card-header d-flex align-items-center">
            <div class="d-flex align-items-center">
              <a>
                <img v-if="item.photo" :src="item.photo" class="avatar" />
              </a>
              <div class="mx-3">
                <a class="text-dark font-weight-600 text-sm"
                  >{{ item.first_name }} {{ item.last_name }}</a
                >
                <small class="d-block text-muted"
                  >Contestant ID: {{ item.contestant_id }}</small
                >
              </div>
            </div>
            <div class="text-right ml-auto" v-if="isEnableVote">
              <b-button
                v-b-modal.modal-prevent-closing
                class="btn btn-sm btn-success btn-icon"
              >
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text">Vote</span>
              </b-button>
            </div>
            <div class="text-right ml-auto" v-else-if="!isEnableVote">
              <b-button class="btn btn-sm btn-danger btn-icon">
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text"
                  ><a style="color: #fff" :href="pay_with_contestant_id">
                    Add Fund to Vote</a
                  ></span
                >
              </b-button>
            </div>
          </div>
          <div class="card-body">
            <img
              v-if="item.photo"
              alt="Image placeholder"
              :src="item.photo"
              class="img-fluid rounded"
            />
            <br /><br />
            <p class="" style="padding: 0px !important">
              <span
                style="marign-bottom: 0px !important"
                v-html="item.about_contestant"
              ></span>
            </p>
            <div class="row align-items-center my-3 pb-3 border-bottom">
              <div class="col-sm-6">
                <div class="icon-actions">
                  <a href="#" class="like active">
                    <i class="ni ni-like-2"></i>
                    <span class="text-muted"> {{ item.vote_count }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3"></div>
    </div>
    <!--End Search Results-->
    <!-- Start Voting Model -->
    <div v-for="item in Contestant" v-bind:key="item.id">
      <!-- hide-backdrop -->
      <b-modal
        :header-bg-variant="headerBgVariant"
        :header-text-variant="headerTextVariant"
        id="modal-prevent-closing"
        ref="modal"
        title="Vote Now!"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
      >
        <div class="d-block">
          <p>
            <b>Vote for Contestant:</b> {{ item.first_name }}
            {{ item.last_name }}, <b>ID:</b> {{ item.contestant_id }}<br />Vote
            Cost: N{{ voting_cost }}/vote
          </p>
        </div>

        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group
            :state="nameState"
            :label="minimum_voting"
            label-for="name-input"
            invalid-feedback="Vote is required"
          >
            <b-form-select
              id="name-input"
              v-model="voteCount"
              :options="variants"
              :state="nameState"
              required
            ></b-form-select>
            <!-- <b-form-input
                  id="name-input"
                  v-model="name"
                  :state="nameState"
                  required
                ></b-form-input> -->
          </b-form-group>
        </form>
      </b-modal>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

import "bootstrap-vue";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "VoteContestant",
  data() {
    return {
      search: "",
      Contestant: [],
      constestantId: "",
      walletBalance: "",
      walletBalanceAmount: "",
      name: "",
      nameState: null,
      submittedNames: [],
      voteCount: "",
      variants: [2, 4, 5, 10, 20, 30, 40, 50, 60, 80, 100],
      headerBgVariant: "primary",
      headerTextVariant: "dark",
      isEnableVote: "",
      voting_cost: "",
      minimum_voting: "",
      minimum_vot_qty: "",
      token: JSON.parse(localStorage.getItem("userData")).token,
      contestant_id: "",
      pay_with_contestant_id: "",
    };
  },
  computed: {},

  created() {
    this.contestant_id = this.$route.params.id;
    //console.log('this.contestant_id', this.contestant_id)

    this.submitSearch();

    this.$store.dispatch("loadWalletBalance").then((res) => {
      this.walletBalance = "N " + String(res);
    });
    this.pay_with_contestant_id = "/#/wallet?id=" + this.contestant_id;
  },
  methods: {
    auditionVoting(voteQty) {
      ////console.log('voteQty', voteQty)
      ////console.log('this.minimum_vot_qty', this.minimum_vot_qty, this.voting_cost, this.walletBalanceAmount)
      const totalVoteAmount = voteQty * this.voting_cost;
      ////console.log('totalVoteAmount', totalVoteAmount, 'id: ', this.constestantId)
      if (voteQty < this.minimum_vot_qty) {
        alert("Voting amount selected is too low!");
      } else if (totalVoteAmount > this.walletBalanceAmount) {
        alert("You don't enough fund in your wallet to vote!");
      } else {
        var headers = { Authorization: `Token ${this.token}` };
        var postData = {
          vote_qty: voteQty,
          vote_rate: this.voting_cost,
          vote_cost: totalVoteAmount,
          contestant_id: this.constestantId,
        };
        axios
          .post(API_URL + "vote/audition-voting/", postData, {
            headers: headers,
          })
          .then(() => {
            alert("Your Voting was Successful!");
            this.updateWallet_ContestantInfo();
          })
          .catch(() => {
            alert("Something went wrong, Try Again!");
            this.updateWallet_ContestantInfo();
          });
      }
    },
    submitSearch() {
      var headers = { Authorization: `Token ${this.token}` };
      //const searchInput = parseInt(this.search, 10);
      ////console.log('searchInput', searchInput)
      const searchInput = this.contestant_id;
      //console.log('searchInput auto', this.contestant_id)

      axios
        .get(API_URL + "contest/get-contestants/" + searchInput, {
          headers: headers,
        })
        .then((res) => {
          ////console.log('getContestants', res.data)
          this.Contestant = res.data;
          this.constestantId = res.data[0].contestant_id;
          ////console.log('this.constestantId 1', this.constestantId)
          this.okCheck(res.data[0].contestant_id);
        })
        .catch(() => {
          alert("You have entered Invalid Contestant ID");
        });
    },

    checkFormValidity() {
      const valid = true; //this.$refs.form.checkValidity()
      this.nameState = valid;
      return valid;
    },
    resetModal() {
      this.voteCount = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name);
      ////console.log('voteCount', this.voteCount)
      this.auditionVoting(this.voteCount);
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    okCheck(constestantId) {
      var headers = { Authorization: `Token ${this.token}` };
      axios
        .get(API_URL + "vote/can-user-vote-balance/" + constestantId, {
          headers: headers,
        })
        .then((res) => {
          ////console.log('okCheck ==', res.data.data)
          this.isEnableVote = res.data.data;
          this.minimum_voting = "Min " + res.data.minimum_voting + " Votes";
          this.minimum_vot_qty = res.data.minimum_voting;
          this.voting_cost = res.data.voting_cost;
        });

      this.$store.dispatch("loadWalletBalance").then((res) => {
        this.walletBalanceAmount = res;
      });
    },
    updateWallet_ContestantInfo() {
      this.submitSearch();

      this.$store.dispatch("loadWalletBalance").then((res) => {
        ////console.log('res', res)
        this.walletBalance = "N " + String(res);
      });
    },
  },
};
</script>
<style></style>
