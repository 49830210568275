<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Card stats -->
      <div class="row">
        <!-- sub-title="2,356" -->
        <div class="col-xl-12 col-lg-12">
          <stats-card
            title="Copy your Referral Link below"
            type="gradient-orange"
            icon="ni ni-chart-pie-35"
            class="mb-4 mb-xl-0"
          >
            <template slot="footer">
              <h3>
                <b>Referral Link:</b
                ><base-input
                  type="text"
                  v-model="referralCode"
                  id="referral-link"
                  input-classes="form-control-alternative"
                />
              </h3>
              <button @click="Copy()" class="btn btn-primary">
                Copy Referral Link
              </button>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <!-- Dark table -->
      <div class="row">
        <div class="col">
          <div class="card bg-default shadow">
            <div class="card-header bg-transparent border-0">
              <h3 class="text-white mb-0">Referral Bounses</h3>
            </div>
            <div class="table-responsive">
              <table class="table align-items-center table-dark table-flush">
                <!-- Dark table -->
                <thead class="thead-dark">
                  <th>Referred User</th>
                  <th>Bonus Amount</th>
                  <th>Start Investment date</th>
                  <th>Signup Date</th>
                </thead>
                <tbody>
                  <tr v-for="item in referralbonus" v-bind:key="item.id">
                    <td>{{ item.referred_user }}</td>
                    <td>N {{ item.referral_bonus }}.00</td>
                    <td v-if="item.date_start_investment">
                      {{ item.date_start_investment | formatdate }}
                    </td>
                    <td v-if="!item.date_start_investment">Not yet.</td>
                    <td>{{ item.date_signup | formatdate }}</td>
                  </tr>
                </tbody>
                <!-- Dark table -->
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Dark table -->
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";
import API_URL from "./../common/config";
import REFERRAL_API_URL from "./../common/url-config";
import Vue from "vue";
import moment from "moment";

Vue.filter("formatdate", function (value) {
  if (value) {
    return moment(String(value)).format("MM-DD-YYYY"); //"MM/DD/YYYY hh:mm"
  }
});

export default {
  name: "referral-bonus",
  components: {},
  data() {
    return {
      referralCode: "",
      referralbonus: [],
    };
  },
  created() {
    this.getReferralbonus();
    this.getReferralCode();
  },
  methods: {
    getReferralbonus() {
      this.$store.dispatch("loadReferralBonus").then((data) => {
        this.referralbonus = data;
        console.log("referralbonus == ", data);
      });
    },
    getReferralCode() {
      var token = JSON.parse(localStorage.getItem("userData")).token;
      var headers = { Authorization: `Token ${token}` };

      axios
        .get(API_URL + "referral/referral-code/", { headers: headers })
        .then((res) => {
          this.referralCode = REFERRAL_API_URL + res.data[0].referral_code;
        });
    },
    Copy() {
      var Url = document.getElementById("referral-link");
      Url.value = this.referralCode;
      Url.focus();
      Url.select();
      document.execCommand("Copy");
      alert("Referral Linked Copied");
    },
  },
};
</script>
<style></style>
