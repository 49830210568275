<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-header bg-transparent pb-5">
          <div class="text-muted text-center mt-2 mb-3">
            <small>Sign in</small>
          </div>
          <div class="" align="center">
            <h4>Login to Vote for your Contestant</h4>
            <h3>Contestant ID: {{ contestant_id }}</h3>
          </div>
        </div>

        <div
          class="card-body px-lg-5 py-lg-5"
          style="margin-top: -50px !important"
        >
          <form role="form" @submit.prevent="onSignin">
            <base-input
              class="input-group-alternative mb-3"
              placeholder="Username"
              addon-left-icon="ni ni-single-02"
              v-model="model.username"
            >
            </base-input>

            <base-input
              class="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
            >
            </base-input>

            <base-checkbox class="custom-control-alternative">
              <span class="text-muted">Remember me</span>
            </base-checkbox>
            <div class="text-center">
              <!-- <base-button type="primary" class="my-4"> -->
              <button type="submit" class="btn btn-primary">Sign in</button>
              <!-- </base-button> -->
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <a href="#" class="text-light"><small>Forgot password?</small></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AuditionLogin",
  data() {
    return {
      model: {
        username: "",
        password: "",
        contestant_id: "",
      },
    };
  },
  created() {
    this.contestant_id = this.$route.params.id;
    ////console.log('this.contestant_id', this.contestant_id)
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    token() {
      return this.$store.getters.token;
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        ////console.log('token 1=', value)
        this.$router.push("/vote-contestant/" + this.contestant_id);
      }
    },
    token(value) {
      console.log("token 2=", value);
    },
  },
  methods: {
    onSignin() {
      ////console.log('username', this.model.username, 'password', this.model.password)
      this.$store.dispatch("signUserIn", {
        username: this.model.username,
        password: this.model.password,
      });
    },
  },
};
</script>
<style></style>
